import {defineNuxtRouteMiddleware, navigateTo} from '#imports';
import {LOGIN} from '@/composables/useURL';
import {useAuthStore} from '@/stores/auth';

// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();

  if (process.client) {
    await authStore.checkAuth();
  }

  if (process.client && to.path.includes('cabinet') && !authStore.isAuth) {
    return await navigateTo(LOGIN);
  }
});
