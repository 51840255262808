import {defineStore} from 'pinia';
import {computed, ref} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {ADD_COMPARE, CLEAR_COMPARE, DELETE_COMPARE, GET_COMPARE} from '@/composables/useURL';
import type {
  ICompareGetFullItemResponse,
  ICompareGetFullResponse,
  ICompareGetFullSpecResponse,
  ICompareGetFullTabResponse,
  ITmpItems,
} from '@/types/store/compare';

export const useCompareStore = defineStore('compare', () => {
  const isEmpty = ref(false);
  const isCompare = ref(false);
  const isFetching = ref(true);
  const isFetchingById = ref(true);
  const ids = ref<number[]>([]);
  const tabs = ref<ICompareGetFullTabResponse[]>([]);
  const specs = ref<ICompareGetFullSpecResponse[]>([]);
  const items = ref<ICompareGetFullItemResponse[]>([]);
  const itemsPrevDiff = ref<ICompareGetFullItemResponse[]>([]);
  const selectedItems = ref<ICompareGetFullItemResponse[]>([]);
  const selectedTab = ref<ICompareGetFullTabResponse | null>(null);

  const selectedItemsUnicalList = computed(() => {
    let tmpItems: ITmpItems[] = [];
    if (selectedItems.value.length > 1) {
      selectedItems.value.forEach(({props}) => {
        props.forEach((prop) => {
          if (!tmpItems.some(({specId, value}) => specId === prop.specId && value === prop.value)) {
            tmpItems.push(prop);
          }
        });
      });
    } else if (selectedItems.value.length === 1) {
      tmpItems = selectedItems.value[0].props;
    }
    return tmpItems;
  });

  const setSelectedItems = (item: ICompareGetFullItemResponse) => {
    selectedItems.value.push(item);
    items.value = items.value.filter((currItem) => currItem.id !== item.id);
    // filterСharacteristic();
  };

  const removeSelectedItems = (item: ICompareGetFullItemResponse) => {
    items.value.unshift(item);
    selectedItems.value = selectedItems.value.filter((currItem) => currItem.id !== item.id);
    // filterСharacteristic();
  };

  const get = async () => {
    isFetching.value = true;
    const data = (await useAuthFetch(GET_COMPARE)) as number[];
    isFetching.value = false;
    ids.value = data;
  };

  const clearStore = () => {
    // items.value = [];
    // specs.value = [];
    tabs.value = [];
    itemsPrevDiff.value = [];
    selectedItems.value = [];
  };

  const setCompareItems = (data: ICompareGetFullResponse) => {
    const resLength = data.items?.length;
    if (resLength !== 0 && resLength !== undefined) {
      isEmpty.value = false;
    } else {
      isEmpty.value = true;
      return;
    }
    if (data.items === undefined || data.tabs === undefined) {
      return;
    }
    items.value = data.items;
    itemsPrevDiff.value = JSON.parse(JSON.stringify(data.items)) as ICompareGetFullItemResponse[];
    specs.value = data.specs || [];
    tabs.value = tabs.value.length ? tabs.value : data.tabs;
  };

  const deleteItems = () => {
    items.value = [];
  };

  const getById = async (id?: number) => {
    const idFinal = id ?? selectedTab.value?.id;
    deleteItems();

    isFetchingById.value = true;
    // TODO adding `.finally` to the promise breaks comparison table layout, figure out why
    try {
      const data = (await useAuthFetch(GET_COMPARE, {
        query: {type: 'full', id: idFinal, isCompare: isCompare.value},
      })) as ICompareGetFullResponse;

      setCompareItems(data);
    } finally {
      isFetchingById.value = false;
    }
  };

  const setTab = (val: ICompareGetFullTabResponse) => {
    selectedTab.value = val;
  };

  const getFull = async () => {
    isFetching.value = true;
    const options = {query: {type: 'full'}};
    const data: ICompareGetFullResponse = (await useAuthFetch(
      GET_COMPARE,
      options,
    )) as ICompareGetFullResponse;
    isFetching.value = false;
    clearStore();
    setCompareItems(data);
    if (data.tabs?.length) {
      setTab(data.tabs[0]);
      void getById();
    }
  };

  const add = async (id: number) => {
    const options = {query: {productId: id}};
    await useAuthFetch(ADD_COMPARE, options);
    ids.value = [...ids.value, id];
  };

  const addByArticle = async (id: string) => {
    const options = {query: {articleId: id}};
    // TODO: Допилить логику добавления товара по артиклу
    await useAuthFetch(ADD_COMPARE, options);
    if (selectedTab.value === null) {
      return;
    }
    await getById(selectedTab.value.id);
  };

  const delete_ = async (id: number) => {
    const options = {query: {productId: id}};
    await useAuthFetch(DELETE_COMPARE, options);
    ids.value = ids.value.filter((el) => el !== id);
    items.value = items.value.filter((el) => el.id !== id);
    itemsPrevDiff.value = JSON.parse(JSON.stringify(items.value)) as ICompareGetFullItemResponse[];
    if (selectedTab.value === null) {
      return;
    }
    selectedTab.value.count -= 1;
    if (items.value.length === 0) {
      clearStore();
      await getFull();
    }
  };

  const clear = async (id?: number) => {
    const currId = id || selectedTab.value?.id;
    if (currId === undefined) {
      return;
    }
    const options = {query: {id: currId}};
    await useAuthFetch(CLEAR_COMPARE, options);
    tabs.value = tabs.value.filter((el) => el.id !== currId);
    if (!tabs.value.length) {
      clearStore();
      await getFull();
      await get();
      return;
    }
    setTab(tabs.value[0]);
    await get();
  };

  const isProductInList = (id: number) => {
    return ids.value.includes(id);
  };

  const changeProductContain = (id: number) => {
    isProductInList(id) ? delete_(id) : add(id);
  };

  return {
    isEmpty,
    isCompare,
    isFetching,
    isFetchingById,
    ids,
    tabs,
    specs,
    items,
    selectedItems,
    selectedTab,

    selectedItemsUnicalList,

    setSelectedItems,
    removeSelectedItems,
    get,
    getById,
    getFull,
    add,
    addByArticle,
    delete: delete_,
    clear,
    setTab,
    isProductInList,
    changeProductContain,
  };
});
