import revive_payload_client_4sVQNw7RlN from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/bitrix/ext_www/t-mash.ru/tmash-front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bKSoGl1L7p from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/home/bitrix/ext_www/t-mash.ru/tmash-front/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import clickOutside_ZB3Eg0wOVk from "/home/bitrix/ext_www/t-mash.ru/tmash-front/plugins/clickOutside.ts";
import loadScript_nTDapWxbUH from "/home/bitrix/ext_www/t-mash.ru/tmash-front/plugins/loadScript.ts";
import maska_UHaKf2z1iQ from "/home/bitrix/ext_www/t-mash.ru/tmash-front/plugins/maska.ts";
import print_TGj5mzLAgH from "/home/bitrix/ext_www/t-mash.ru/tmash-front/plugins/print.ts";
import tooltip_Di9mlKVbnI from "/home/bitrix/ext_www/t-mash.ru/tmash-front/plugins/tooltip.ts";
import unqueId_DjpNGambnv from "/home/bitrix/ext_www/t-mash.ru/tmash-front/plugins/unqueId.ts";
import yandexMap_M2Kz4NfiIu from "/home/bitrix/ext_www/t-mash.ru/tmash-front/plugins/yandexMap.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_bKSoGl1L7p,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  chunk_reload_client_UciE0i6zes,
  clickOutside_ZB3Eg0wOVk,
  loadScript_nTDapWxbUH,
  maska_UHaKf2z1iQ,
  print_TGj5mzLAgH,
  tooltip_Di9mlKVbnI,
  unqueId_DjpNGambnv,
  yandexMap_M2Kz4NfiIu
]