import 'sass/base/tooltip.sass';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/animations/shift-away.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/svg-arrow.css';

import VueTippy from 'vue-tippy';
import {defineNuxtPlugin} from '#imports';

// https://atomiks.github.io/tippyjs/v6/getting-started/
// https://vue-tippy.netlify.app

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTippy, {
    directive: 'vuetippy',
    component: 'vuetippy',
    defaultProps: {
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['left', 'bottom-start'],
            },
          },
        ],
      },
      theme: 'custom',
      animation: 'shift-away',
      interactive: true,
      placement: 'top',
      allowHTML: true,
      delay: [300, 0],
      duration: [300, 0],
      zIndex: 9,
      // onCreate(instance: any) {
      //   const closeButton = document.createElement("button")
      //   closeButton.addEventListener("click", () => {
      //     instance.hide()
      //   })
      //   closeButton.setAttribute("class", "tippy-close-button")
      //   closeButton.innerHTML = `<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      //                             <path d="M18.3 5.70999C18.2075 5.61729 18.0976 5.54374 17.9766 5.49356C17.8556 5.44338 17.726 5.41755 17.595 5.41755C17.464 5.41755 17.3343 5.44338 17.2134 5.49356C17.0924 5.54374 16.9825 5.61729 16.89 5.70999L12 10.59L7.10999 5.69999C7.01741 5.60741 6.9075 5.53397 6.78654 5.48387C6.66557 5.43376 6.53593 5.40797 6.40499 5.40797C6.27406 5.40797 6.14442 5.43376 6.02345 5.48387C5.90249 5.53397 5.79258 5.60741 5.69999 5.69999C5.60741 5.79258 5.53397 5.90249 5.48387 6.02345C5.43376 6.14442 5.40797 6.27406 5.40797 6.40499C5.40797 6.53593 5.43376 6.66557 5.48387 6.78654C5.53397 6.9075 5.60741 7.01741 5.69999 7.10999L10.59 12L5.69999 16.89C5.60741 16.9826 5.53397 17.0925 5.48387 17.2135C5.43376 17.3344 5.40797 17.4641 5.40797 17.595C5.40797 17.7259 5.43376 17.8556 5.48387 17.9765C5.53397 18.0975 5.60741 18.2074 5.69999 18.3C5.79258 18.3926 5.90249 18.466 6.02345 18.5161C6.14442 18.5662 6.27406 18.592 6.40499 18.592C6.53593 18.592 6.66557 18.5662 6.78654 18.5161C6.9075 18.466 7.01741 18.3926 7.10999 18.3L12 13.41L16.89 18.3C16.9826 18.3926 17.0925 18.466 17.2135 18.5161C17.3344 18.5662 17.4641 18.592 17.595 18.592C17.7259 18.592 17.8556 18.5662 17.9765 18.5161C18.0975 18.466 18.2074 18.3926 18.3 18.3C18.3926 18.2074 18.466 18.0975 18.5161 17.9765C18.5662 17.8556 18.592 17.7259 18.592 17.595C18.592 17.4641 18.5662 17.3344 18.5161 17.2135C18.466 17.0925 18.3926 16.9826 18.3 16.89L13.41 12L18.3 7.10999C18.68 6.72999 18.68 6.08999 18.3 5.70999Z" fill="#9C9C9C"/>
      //                             </svg>`
      //   const content = instance.popper.getElementsByClassName('tippy-content')[0]
      //   content.appendChild(closeButton)
      // }
    },
  });
});
