<template>
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.5 46.5C53.6046 46.5 54.5 45.6046 54.5 44.5V16.5C54.5 15.3954 53.6046 14.5 52.5 14.5H12.5C11.3954 14.5 10.5 15.3954 10.5 16.5V44.5C10.5 45.6046 11.3954 46.5 12.5 46.5H52.5ZM58.5 44.5C58.5 47.8137 55.8137 50.5 52.5 50.5H12.5C9.18629 50.5 6.5 47.8137 6.5 44.5V16.5C6.5 13.1863 9.18629 10.5 12.5 10.5H52.5C55.8137 10.5 58.5 13.1863 58.5 16.5V44.5Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5 56.5C22.5 55.3954 23.3954 54.5 24.5 54.5H40.5C41.6046 54.5 42.5 55.3954 42.5 56.5C42.5 57.6046 41.6046 58.5 40.5 58.5H24.5C23.3954 58.5 22.5 57.6046 22.5 56.5Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    color: {type: String, required: false, default: '#4B6286'},
  },
};
</script>

<style lang="scss">
path {
  transition: 0.3s fill;
}
</style>
