import {defineStore} from 'pinia';
import {computed, ref} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {
  GET_ARTICLES_FOR_INFOCENTER,
  GET_ARTICLE_BY_CODE,
  GET_ARTICLE_OTHER_BY_CODE,
  GET_ARTICLE_SEARCH,
  GET_NEWS_BY_PATH,
  GET_NEWS_FOR_INFOCENTER,
  GET_NEWS_SEARCH,
  GET_NEWS_VENDOR_BY_VENDOR_CODE,
  GET_OTHER_NEWS_FOR_INFOCENTER,
  GET_SHORTNEWS_FOR_INFOCENTER,
  GET_VIDEOS_FOR_INFOCENTER,
} from '@/composables/useURL';
import {emptyVendorItem} from '@/services/vendors';
import type {
  IArticle,
  IArticleById,
  IArticleListByTag,
  IArticleVideo,
  IArticlesList,
  IDatumArticles,
  INews,
  INewsByPath,
  INewsList,
  INewsListByTag,
  INewsObj,
  INewsOther,
  INewsShort,
  IShortNewsList,
} from '@/types/store/article';
import type {IVendorsFilter} from '@/types/store/vendor';
import {useRootStore} from './root';

export const useInfoStore = defineStore('info', () => {
  const page = ref(1);
  const maxPage = ref(0);
  const pageSize = ref(10);

  const shortNews = ref<INewsShort[]>([]);
  const mainNews = computed(() => shortNews.value.filter((n) => n.isMain));
  const subNews = computed(() => shortNews.value.filter((n) => !n.isMain));

  const videos = ref<IArticleVideo[]>([]);
  const mainVideos = computed(() => videos.value.filter((n) => n.isMain));
  const subVideos = computed(() => videos.value.filter((n) => !n.isMain));

  const articles = ref<IArticle[]>([]);
  const mainArticles = computed(() => articles.value.filter((n) => n.isWide));
  const subArticles = computed(() => articles.value.filter((n) => !n.isWide));

  const otherNews = ref<INewsOther[]>([]);
  const newsList = ref<INews[]>([]);
  const item = ref<INewsObj | null>(null);
  const otherItems = ref<IDatumArticles[]>();

  const calendarEventFilters = ref<IVendorsFilter[]>([emptyVendorItem]);
  const calendarBrandFilters = ref<IVendorsFilter[]>([emptyVendorItem]);

  const getNews = async (path: string) => {
    const news = (await useAuthFetch(GET_NEWS_BY_PATH(path))) as INewsByPath;
    videos.value = news.Video;
    shortNews.value = news.ShortNews;
    otherNews.value = news.OtherNews;
    articles.value = news.Articles;
  };

  const getNewsList = async (newPage: number) => {
    const opt = {
      query: {page: newPage, pageSize: pageSize.value},
    };
    const {data, maxPage: newMaxPage} = (await useAuthFetch(
      GET_NEWS_FOR_INFOCENTER,
      opt,
    )) as INewsList;
    newsList.value = data;
    maxPage.value = newMaxPage;
  };

  const updateNewsList = async (newPage: number) => {
    const opt = {
      query: {page: newPage, pageSize: pageSize.value},
    };
    const {data, maxPage: newMaxPage} = (await useAuthFetch(
      GET_NEWS_FOR_INFOCENTER,
      opt,
    )) as INewsList;
    newsList.value = [...newsList.value, ...data];
    maxPage.value = newMaxPage;
  };

  const getNewsVendorList = async (vendorCode: number | string, newPage: number) => {
    const opt = {
      query: {page: newPage, pageSize: pageSize.value},
    };
    const {data, maxPage: newMaxPage} = (await useAuthFetch(
      GET_NEWS_VENDOR_BY_VENDOR_CODE(vendorCode),
      opt,
    )) as INewsList;
    newsList.value = data;
    maxPage.value = newMaxPage;
  };

  const updateNewsVendorList = async (vendorCode: number | string, newPage: number) => {
    const opt = {
      query: {page: newPage, pageSize: pageSize.value},
    };
    const {data, maxPage: newMaxPage} = (await useAuthFetch(
      GET_NEWS_VENDOR_BY_VENDOR_CODE(vendorCode),
      opt,
    )) as INewsList;
    newsList.value = [...newsList.value, ...data];
    maxPage.value = newMaxPage;
  };

  const getReviewNews = async (isMore = false) => {
    const opt = {
      query: {page: page.value, pageSize: pageSize.value},
    };
    const {data, maxPage: newMaxPage} = (await useAuthFetch(
      GET_SHORTNEWS_FOR_INFOCENTER,
      opt,
    )) as IShortNewsList;
    shortNews.value = isMore ? [...shortNews.value, ...data] : data;
    maxPage.value = newMaxPage;
  };

  const getArticles = async (isMore = false) => {
    const opt = {
      query: {page: page.value, pageSize: pageSize.value},
    };
    const {data, maxPage: newMaxPage} = (await useAuthFetch(
      GET_ARTICLES_FOR_INFOCENTER,
      opt,
    )) as IArticlesList;
    articles.value = isMore ? [...articles.value, ...data] : data;
    maxPage.value = newMaxPage;
  };

  const getVideos = async (isMore = false) => {
    const opt = {
      query: {page: page.value, pageSize: pageSize.value},
    };
    const {data, maxPage: newMaxPage} = (await useAuthFetch(
      GET_VIDEOS_FOR_INFOCENTER,
      opt,
    )) as IShortNewsList;
    // TODO
    videos.value = isMore ? [...videos.value, ...data] : data;
    maxPage.value = newMaxPage;
  };

  const getOtherNews = async () => {
    const {data} = await useAuthFetch(GET_OTHER_NEWS_FOR_INFOCENTER);
    otherNews.value = data;
  };

  const getSingleNews = async (newsCode: string | number) => {
    item.value = null;
    const rootStore = useRootStore();
    const data = (await useAuthFetch(GET_NEWS_BY_PATH(newsCode))) as INewsObj;
    item.value = data;
    rootStore.meta = data.meta;
  };

  const getSingleArticle = async (articleCode: number | string) => {
    item.value = null;
    const rootStore = useRootStore();
    const data = (await useAuthFetch(GET_ARTICLE_BY_CODE(articleCode))) as IArticleById;
    item.value = data;
    console.log(data, 'data');
    rootStore.meta = data.meta;
  };

  const getOtherArticles = async (articleCode: string | number) => {
    const data = (await useAuthFetch(GET_ARTICLE_OTHER_BY_CODE(articleCode))) as IDatumArticles[];
    otherItems.value = data;
  };

  const searchNews = async (query: unknown, isUpdate = false) => {
    const rootStore = useRootStore();
    const opt = {
      query: {...query, pageSize: pageSize.value},
    };
    const {
      data,
      maxPage: newMaxPage,
      meta,
    } = (await useAuthFetch(GET_NEWS_SEARCH, opt)) as INewsListByTag;
    newsList.value = isUpdate ? [...newsList.value, ...data] : data;
    maxPage.value = newMaxPage;
    rootStore.meta = meta;
  };

  const searchArticles = async (articleType: number | string) => {
    const opt = {
      query: {tag: articleType, page: page.value, pageSize: pageSize.value},
    };

    const rootStore = useRootStore();
    const {
      data,
      maxPage: newMaxPage,
      meta,
    } = (await useAuthFetch(GET_ARTICLE_SEARCH, opt)) as IArticleListByTag;
    newsList.value = data;
    maxPage.value = newMaxPage;
    rootStore.meta = meta;
  };

  return {
    page,
    maxPage,

    shortNews,
    mainNews,
    subNews,

    videos,
    mainVideos,
    subVideos,
    getVideos,

    articles,
    mainArticles,
    subArticles,
    getArticles,
    searchArticles,
    getSingleArticle,
    getOtherArticles,

    getNews,
    getReviewNews,
    getSingleNews,
    searchNews,

    otherNews,
    getOtherNews,

    newsList,
    getNewsList,
    updateNewsList,
    getNewsVendorList,
    updateNewsVendorList,

    item,
    otherItems,

    calendarEventFilters,
    calendarBrandFilters,
  };
});
