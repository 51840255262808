<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="2" width="2" height="2" fill="#252525" />
    <rect y="7" width="2" height="2" fill="#252525" />
    <rect y="12" width="2" height="2" fill="#252525" />
    <rect x="4" y="7" width="12" height="2" fill="#252525" />
    <rect x="4" y="2" width="12" height="2" fill="#252525" />
    <rect x="4" y="12" width="12" height="2" fill="#252525" />
  </svg>
</template>
