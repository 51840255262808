<template>
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.62141 16.6252C8.62139 16.6256 8.62141 16.6246 8.62141 16.6252V48.3752C8.62141 48.3757 8.62139 48.3747 8.62141 48.3752C8.62168 48.3755 8.62206 48.3774 8.6225 48.3778C8.62295 48.3783 8.62217 48.3775 8.6225 48.3778C8.62292 48.3778 8.62459 48.3789 8.62516 48.3789H56.3752C56.3757 48.3789 56.3747 48.3789 56.3752 48.3789C56.3755 48.3786 56.3774 48.3783 56.3778 48.3778C56.3783 48.3774 56.3775 48.3781 56.3778 48.3778C56.3778 48.3774 56.3789 48.3757 56.3789 48.3752V16.6252C56.3789 16.6246 56.3789 16.6256 56.3789 16.6252C56.3786 16.6248 56.3783 16.6229 56.3778 16.6225C56.3774 16.6221 56.3781 16.6228 56.3778 16.6225C56.3774 16.6225 56.3757 16.6214 56.3752 16.6214H8.62516C8.62459 16.6214 8.62557 16.6214 8.62516 16.6214C8.62482 16.6217 8.62295 16.6221 8.6225 16.6225C8.62206 16.623 8.62168 16.6248 8.62141 16.6252ZM4.37891 16.6252C4.37891 14.28 6.28002 12.3789 8.62516 12.3789H56.3752C58.7203 12.3789 60.6214 14.28 60.6214 16.6252V48.3752C60.6214 50.7203 58.7203 52.6214 56.3752 52.6214H8.62516C6.28002 52.6214 4.37891 50.7203 4.37891 48.3752V16.6252Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 32.5002C12.5 31.3957 13.3954 30.5002 14.5 30.5002H50.5C51.6046 30.5002 52.5 31.3957 52.5 32.5002C52.5 33.6048 51.6046 34.5002 50.5 34.5002H14.5C13.3954 34.5002 12.5 33.6048 12.5 32.5002Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 24.5002C12.5 23.3957 13.3954 22.5002 14.5 22.5002H50.5C51.6046 22.5002 52.5 23.3957 52.5 24.5002C52.5 25.6048 51.6046 26.5002 50.5 26.5002H14.5C13.3954 26.5002 12.5 25.6048 12.5 24.5002Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 40.5002C12.5 39.3957 13.3954 38.5002 14.5 38.5002H16.5C17.6046 38.5002 18.5 39.3957 18.5 40.5002C18.5 41.6048 17.6046 42.5002 16.5 42.5002H14.5C13.3954 42.5002 12.5 41.6048 12.5 40.5002Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5 40.5002C22.5 39.3957 23.3954 38.5002 24.5 38.5002H40.5C41.6046 38.5002 42.5 39.3957 42.5 40.5002C42.5 41.6048 41.6046 42.5002 40.5 42.5002H24.5C23.3954 42.5002 22.5 41.6048 22.5 40.5002Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.5 40.5002C46.5 39.3957 47.3954 38.5002 48.5 38.5002H50.5C51.6046 38.5002 52.5 39.3957 52.5 40.5002C52.5 41.6048 51.6046 42.5002 50.5 42.5002H48.5C47.3954 42.5002 46.5 41.6048 46.5 40.5002Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    color: {type: String, required: false, default: '#4B6286'},
  },
};
</script>

<style lang="scss">
path {
  transition: 0.3s fill;
}
</style>
