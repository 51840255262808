<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_92_1292)">
      <rect width="7" height="7" fill="#252525" />
      <rect y="9" width="7" height="7" fill="#252525" />
      <rect x="9" width="7" height="7" fill="#252525" />
      <rect x="9" y="9" width="7" height="7" fill="#252525" />
    </g>
    <defs>
      <clipPath id="clip0_92_1292">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
