<template>
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.9143 30.0858C21.6953 30.8668 21.6953 32.1332 20.9143 32.9142L13.1618 40.6667C12.7889 41.0383 12.4931 41.4799 12.2912 41.9661C12.0893 42.4523 11.9854 42.9735 11.9854 43.5C11.9854 44.0265 12.0893 44.5477 12.2912 45.0339C12.4931 45.5201 12.7889 45.9617 13.1618 46.3333L13.1643 46.3358L18.6668 51.8382C19.0384 52.2112 19.4799 52.507 19.9661 52.7089C20.4523 52.9108 20.9736 53.0147 21.5001 53.0147C22.0265 53.0147 22.5478 52.9108 23.034 52.7089C23.5202 52.507 23.9618 52.2112 24.3334 51.8382L32.0859 44.0858C32.8669 43.3047 34.1332 43.3047 34.9143 44.0858C35.6953 44.8668 35.6953 46.1332 34.9143 46.9142L27.1667 54.6618V54.6618C26.4238 55.407 25.5399 55.9995 24.568 56.4031C23.5955 56.8069 22.553 57.0147 21.5001 57.0147C20.4472 57.0147 19.4046 56.8069 18.4322 56.4031C17.4603 55.9995 16.5776 55.4082 15.8346 54.6629V54.6629L10.3383 49.1667V49.1667C9.59318 48.4237 9.0005 47.5397 8.59698 46.5679C8.1932 45.5955 7.98535 44.5529 7.98535 43.5C7.98535 42.4471 8.1932 41.4045 8.59698 40.4321C9.0005 39.4603 9.59176 38.5777 10.3369 37.8347L10.3383 37.8333L18.0859 30.0858C18.8669 29.3047 20.1332 29.3047 20.9143 30.0858Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9142 49.0858C16.6953 49.8668 16.6953 51.1332 15.9142 51.9142L7.91421 59.9142C7.13317 60.6953 5.86683 60.6953 5.08579 59.9142C4.30474 59.1332 4.30474 57.8668 5.08579 57.0858L13.0858 49.0858C13.8668 48.3047 15.1332 48.3047 15.9142 49.0858Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.9142 5.08579C60.6953 5.86683 60.6953 7.13317 59.9142 7.91421L51.9142 15.9142C51.1332 16.6953 49.8668 16.6953 49.0858 15.9142C48.3047 15.1332 48.3047 13.8668 49.0858 13.0858L57.0858 5.08579C57.8668 4.30474 59.1332 4.30474 59.9142 5.08579Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.9142 35.0858C38.6953 35.8668 38.6953 37.1332 37.9142 37.9142L31.9142 43.9142C31.1332 44.6953 29.8668 44.6953 29.0858 43.9142C28.3047 43.1332 28.3047 41.8668 29.0858 41.0858L35.0858 35.0858C35.8668 34.3047 37.1332 34.3047 37.9142 35.0858Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.9142 27.0858C30.6953 27.8668 30.6953 29.1332 29.9142 29.9142L23.9142 35.9142C23.1332 36.6953 21.8668 36.6953 21.0858 35.9142C20.3047 35.1332 20.3047 33.8668 21.0858 33.0858L27.0858 27.0858C27.8668 26.3047 29.1332 26.3047 29.9142 27.0858Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.4321 8.59698C41.4045 8.1932 42.4471 7.98535 43.5 7.98535C44.5529 7.98535 45.5955 8.1932 46.5679 8.59698C47.5397 9.0005 48.4223 9.59176 49.1653 10.3369V10.3369L54.6618 15.8334V15.8334C55.407 16.5764 55.9995 17.4603 56.4031 18.4322C56.8069 19.4046 57.0147 20.4472 57.0147 21.5001C57.0147 22.553 56.8069 23.5955 56.4031 24.568C55.9995 25.5399 55.4082 26.4226 54.6629 27.1656V27.1656L46.9142 34.9143C46.1332 35.6953 44.8668 35.6953 44.0858 34.9143C43.3047 34.1332 43.3047 32.8669 44.0858 32.0859L51.8382 24.3334C52.2112 23.9618 52.507 23.5202 52.7089 23.034C52.9108 22.5478 53.0147 22.0265 53.0147 21.5001C53.0147 20.9736 52.9108 20.4523 52.7089 19.9661C52.507 19.4799 52.2112 19.0384 51.8382 18.6667L46.3333 13.1618C45.9617 12.7889 45.5201 12.4931 45.0339 12.2912C44.5477 12.0893 44.0265 11.9854 43.5 11.9854C42.9735 11.9854 42.4523 12.0893 41.9661 12.2912C41.4799 12.4931 41.0383 12.7889 40.6667 13.1618L32.9142 20.9143C32.1332 21.6953 30.8668 21.6953 30.0858 20.9143C29.3047 20.1332 29.3047 18.8669 30.0858 18.0859L37.8333 10.3383L37.8347 10.3369C38.5777 9.59176 39.4603 9.0005 40.4321 8.59698Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.0858 16.0858C28.8668 15.3047 30.1332 15.3047 30.9142 16.0858L48.9142 34.0858C49.6953 34.8668 49.6953 36.1332 48.9142 36.9142C48.1332 37.6953 46.8668 37.6953 46.0858 36.9142L28.0858 18.9142C27.3047 18.1332 27.3047 16.8668 28.0858 16.0858Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0858 28.0858C16.8668 27.3047 18.1332 27.3047 18.9142 28.0858L36.9142 46.0858C37.6953 46.8668 37.6953 48.1332 36.9142 48.9142C36.1332 49.6953 34.8668 49.6953 34.0858 48.9142L16.0858 30.9142C15.3047 30.1332 15.3047 28.8668 16.0858 28.0858Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    color: {type: String, required: false, default: '#4B6286'},
  },
};
</script>

<style lang="scss">
path {
  transition: 0.3s fill;
}
</style>
