import {defineStore} from 'pinia';
import {computed, ref, useRuntimeConfig} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {
  ADD_ARTICLE,
  ADD_TO_BASKET,
  CHANGE_BASKET,
  DELETE_FROM_BASKET,
  GET_BASKET,
  SHARE_BASKET,
  SHARE_MAIL_BASKET,
} from '@/composables/useURL';
import type {
  IGetBasketAsideResponse,
  IGetBasketOneResponse,
  IGetBasketOneServiceListResponse,
  IGetBasketZeroResponse,
  IProductBasketOneResponse,
} from '@/types/store/basket';

const shareMail = async (mail: string) => {
  await useAuthFetch(SHARE_MAIL_BASKET, {
    query: {
      mail,
    },
  });
};

export const useBasketStore = defineStore('basket', () => {
  const servicesPrice = ref(0);
  const isFullLoaded = ref(false);
  const aside = ref<IGetBasketAsideResponse>();
  const products = ref<IProductBasketOneResponse[]>([]);
  const localProducts = ref<(number | string)[]>([]);
  const shareProducts = ref<IProductBasketOneResponse[]>([]);
  const selectedServices = ref<unknown[]>([]);
  const smallInfo = ref<IGetBasketZeroResponse>({sum: 0, count: 0, ids: [], products: []});

  const productsCount = computed(() => products.value.length);

  const servicesCount = computed(() => selectedServices.value.length);

  const productsPrice = computed(() =>
    useRuntimeConfig().public.hidePrices
      ? 0
      : products.value.reduce((prev, {price, count}) => prev + price * count, 0),
  );

  const resultPrice = computed(() => productsPrice.value + servicesPrice.value);

  const productsBonuses = computed(() =>
    products.value.reduce((prev, {bonuses, count}) => prev + bonuses * count, 0),
  );

  const serviceBonuses = computed(() => {
    let bonuses = 0;
    selectedServices.value.forEach((id) => {
      if (!products.value) return;
      products.value.forEach((prod) => {
        prod.services?.forEach((serv) => {
          serv.list.forEach((item) => {
            if (item.id === id) {
              bonuses += item.bonuses;
            }
          });
        });
      });
    });
    return bonuses;
  });

  const resultBonuses = computed(() => productsBonuses.value + serviceBonuses.value);

  const getSmallInfoProductsCount = computed(() => smallInfo.value.products.length);

  const servicesDetailed = computed(() => {
    const services: (IGetBasketOneServiceListResponse & {productName: string})[] = [];
    selectedServices.value.forEach((id) => {
      if (!products.value) return;
      products.value.forEach((prod) => {
        prod.services?.forEach((serv) => {
          serv.list.forEach((item) => {
            if (item.id === id) {
              services.push({...item, productName: prod.name});
            }
          });
        });
      });
    });
    return services;
  });

  const servicesPricePush = (price: number) => {
    servicesPrice.value += price;
  };

  const setServicesPrice = (product: IProductBasketOneResponse) => {
    product.services?.forEach((serv) => {
      serv.list.forEach((item) => {
        if (product.selectedServices?.includes(item.id)) {
          servicesPricePush(item.price);
        }
      });
    });
  };

  const setSelectedServices = () => {
    products.value.forEach((product) => {
      product.services?.forEach(({list}) => {
        list.forEach((item) => {
          product.selectedServices?.forEach((id) => {
            if (item.id === id && !selectedServices.value.includes(id)) {
              selectedServices.value = [...selectedServices.value, id];
            }
          });
        });
      });
    });
  };

  const get = async (type: number | string = 0) => {
    const options = {
      query: {
        type,
      },
    };
    const data = (await useAuthFetch(GET_BASKET, options)) as IGetBasketOneResponse;
    if (data.sum !== undefined && data !== undefined && type === 0) {
      smallInfo.value = data;
    } else if (
      (type === 1 || type === 'full') &&
      data.aside !== undefined &&
      data.products !== undefined
    ) {
      servicesPrice.value = 0;
      products.value = data.products;
      aside.value = data.aside;
      data.products.forEach((el) => {
        setServicesPrice(el);
      });

      if (data) {
        setSelectedServices();
      }
    }
    if (type === 'full') {
      isFullLoaded.value = true;
    }
  };

  const add = async (id: number, count = 1, isService = false) => {
    await useAuthFetch(ADD_TO_BASKET, {
      query: {
        productId: id,
        quantity: count,
      },
    });
    await get();
    products.value = products.value.map((el) => {
      if (el.id === id) {
        el.count += count;
      }
      return el;
    });
    if (isService) {
      return;
    }
    smallInfo.value.ids.push(id);
    localProducts.value.push(id);
  };

  // TODO
  const articleAdd = async (code: string) => {
    const data = await useAuthFetch(ADD_ARTICLE, {
      query: {
        code,
      },
    });
    if (data.code === 400) {
      return 'Артикул не найден';
    }

    data.count = 1;
    setServicesPrice(data);
    if (!products.value) {
      return '';
    }

    const pr = products.value.find((el) => el.code === code.toLowerCase());
    pr ? pr.count++ : products.value.push(data);
    localProducts.value.push(data.id);
    void get();

    return '';
  };

  const change = async (id: number, count: number) => {
    await useAuthFetch(CHANGE_BASKET, {
      query: {
        productId: id,
        quantity: count,
      },
    });
    void get();
  };

  const delete_ = async (id: number) => {
    await useAuthFetch(DELETE_FROM_BASKET, {
      query: {
        productId: id,
      },
    });
    products.value = products.value.filter((el) => el.id !== id);
    smallInfo.value.ids = smallInfo.value.ids.filter((el) => el !== id);
    void get();
  };

  const shareGet = async (basketCode: number) => {
    shareProducts.value = (await useAuthFetch(SHARE_BASKET, {
      query: {
        basketCode,
      },
    })) as IProductBasketOneResponse[];
  };

  const shareAction = async (type: string, basketCode: number) => {
    await useAuthFetch(`${SHARE_BASKET}${type}/`, {
      query: {
        basketCode,
      },
    });
    void get();
    void get('full');
  };

  return {
    servicesPrice,
    isFullLoaded,
    aside,
    products,
    localProducts,
    shareProducts,
    selectedServices,
    smallInfo,

    productsCount,
    servicesCount,
    productsPrice,
    resultPrice,
    productsBonuses,
    serviceBonuses,
    resultBonuses,
    getSmallInfoProductsCount,
    servicesDetailed,

    add,
    articleAdd,
    change,
    delete: delete_,
    shareGet,
    shareMail,
    shareAction,
    get,
  };
});
