import {defineStore} from 'pinia';
import {ref} from '#imports';
// eslint-disable-next-line import/no-cycle
import {fetchFromApi} from '@/composables/useRequest';
import {
  GET_CALENDAR,
  GET_CALENDAR_BRANDS,
  GET_CATALOG,
  GET_MAIN,
  GET_MENU,
  GET_META,
  GET_PAGE_INFO,
  GET_VACANCIES,
} from '@/composables/useURL';
import type {IMeta} from '@/types/store';
import type {
  ICalendarEvent,
  IChild2Response,
  IContentResponse,
  IIndexResponse,
  IMenuResponse,
  IVacanciesResponse,
} from '@/types/store/store';
import {normalizePath} from '@/utils/router';

export const fetchPageData = async (path: string) => {
  const response = await fetchFromApi<IContentResponse>(
    GET_PAGE_INFO,
    {
      query: {page: path},
    },
    true,
  );
  if ((response as unknown as {code: number; message: string}).code >= 400) {
    return null;
  }
  return response;
};

export const fetchVacancies = async () => {
  const response = await fetchFromApi<IVacanciesResponse[]>(GET_VACANCIES, {}, true);
  return response;
};

export const useRootStore = defineStore('root', () => {
  const menuData = ref<IMenuResponse>();
  const fetchMenuData = async () => {
    const {0: menuResponse, 1: catalog} = await Promise.all([
      fetchFromApi<IMenuResponse>(GET_MENU),
      fetchFromApi<IChild2Response[]>(GET_CATALOG),
    ]);
    const firstMainMenuSection = menuResponse.main[0];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (firstMainMenuSection) {
      firstMainMenuSection.childs = catalog;
    }
    menuData.value = menuResponse;
  };

  const meta = ref<Partial<IMeta>>({});
  const generalMeta = ref<IMeta>();
  const fetchMeta = async (path: string) => {
    const checkedPath = normalizePath(path);
    const response = await fetchFromApi<IMeta>(
      GET_META,
      {
        query: {page: checkedPath},
      },
      true,
    );

    generalMeta.value = response;
  };

  const mainPageData = ref<IIndexResponse>();
  const fetchMainPageData = async () => {
    const response = await fetchFromApi<IIndexResponse>(
      GET_MAIN,
      {
        query: {isMobile: false},
      },
      true,
    );
    mainPageData.value = response;
  };

  const calendarData = ref<ICalendarEvent[]>();
  const fetchCalendarData = async () => {
    const response = await fetchFromApi<ICalendarEvent[]>(
      GET_CALENDAR,
      {
        query: {isMobile: false},
      },
      true,
    );
    calendarData.value = response;
  };

  const calendarBrands = ref<string[]>();
  const fetchCalendarBrands = async () => {
    const response = await fetchFromApi<string[]>(
      GET_CALENDAR_BRANDS,
      {
        query: {isMobile: false},
      },
      true,
    );
    calendarBrands.value = response;
  };

  const isError = ref(false);

  const width = ref(0);

  return {
    menuData,
    fetchMenuData,

    meta,
    generalMeta,
    fetchMeta,

    mainPageData,
    fetchMainPageData,

    calendarData,
    fetchCalendarData,
    calendarBrands,
    fetchCalendarBrands,

    fetchPageData,

    fetchVacancies,

    // TODO better name
    isError,

    // TODO better name
    width,
  };
});
