import {defineNuxtRouteMiddleware} from '#imports';
import {useCatalogStore} from '@/stores/catalog';

export default defineNuxtRouteMiddleware((to, from) => {
  if (from.path.replaceAll(/[\s/\\]/g, '') !== to.path.replaceAll(/[\s/\\]/g, '')) {
    const store = useCatalogStore();
    store.page = 1;
    store.pageSize = 15;
    store.maxPage = 1;
    store.items = [];
  }
});
