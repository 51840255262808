// useStore
export const GET_MENU = '/menu/';
export const GET_MAIN = '/index/';
export const GET_CATALOG = '/catalog/';
export const GET_META = '/meta/';
export const GET_PAGE_INFO = '/content/';
export const GET_VACANCIES = '/vacancies/';

// auth
export const LOGIN = '/auth/';
export const CHECK_LOGIN = '/auth/check/';
export const LOGOUT = '/auth/logout/';
export const FORGOT_PASSWORD = '/auth/forgot/';
export const RESET_PASSWORD = '/auth/change/';
export const REGISTER_FAST = '/reg/fast/';
export const REGISTER = '/reg/';

// vendors
export const VENDORS = '/vendors/';
export const VENDORS_FILTERS = '/vendors/filters/';
export const VENDOR_BY_CODE = (code: string) => `${VENDORS}${code}/`;

// info path
export const INFO_NEWS = '/info/news/';

// info req
export const GET_NEWS = '/news/';
export const GET_NEWS_BY_PATH = (path: string | number) => `/news/${path}/`;
export const GET_ARTICLE = '/article/';
export const GET_ARTICLE_SEARCH = '/article/search/';

export const GET_ARTICLE_BY_CODE = (code: string | number) => `/article/${code}/`;
export const GET_ARTICLE_OTHER_BY_CODE = (code: string | number) => `/article/${code}/other/`;
export const GET_NEWS_FOR_INFOCENTER = '/news/infocenter/news/';
export const GET_NEWS_VENDOR_BY_VENDOR_CODE = (code: string | number) => `/news/vendor/${code}/`;
export const GET_NEWS_SEARCH = '/news/search/';
export const GET_SHORTNEWS_FOR_INFOCENTER = '/news/infocenter/shortNews/';
export const GET_ARTICLES_FOR_INFOCENTER = '/news/infocenter/Articles/';
export const GET_VIDEOS_FOR_INFOCENTER = '/news/infocenter/video/';
export const GET_CALENDAR = '/news/infocenter/calendar/';
export const GET_CALENDAR_BRANDS = '/news/brands/';
export const GET_OTHER_NEWS_FOR_INFOCENTER = '/news/infocenter/otherNews/';

// basket
export const BASKET = '/basket/';
export const GET_BASKET = '/basket/get/';
export const CHANGE_BASKET = '/basket/change/';
export const ADD_TO_BASKET = '/basket/add/';
export const DELETE_FROM_BASKET = '/basket/delete/';
export const ADD_ARTICLE = '/basket/code/';
export const SHARE_BASKET = '/basket/share/';
export const SHARE_MAIL_BASKET = '/basket/share/mail/';

// favorite
export const GET_FAVORITE_LISTS = '/favorite/lists/';
export const CREATE_FAVORITE_LIST = '/favorite/newList/';
export const GET_FAVORITE_PRODUCTS_BY_LIST = '/favorite/getProductsByList/';
export const GET_LISTS_BY_PRODUCT = '/favorite/listsByProductId/';
export const REMOVE_PRODUCT_FROM_FAVORITE_LIST = '/favorite/delete/';
export const ADD_PRODUCT_TO_FAVORITE_LIST = '/favorite/add/';
export const REMOVE_PRODUCT_FAVORITE_LIST = '/favorite/removeList/';
export const GET_PRODUCTS_IN_FAVORITE = '/favorite/';
export const GET_PDF_BY_LIST_ID = 'favorite/pdf/';

// catalog
export const CATALOG = '/catalog/';
export const GET_CATALOG_PRODUCTS = '/catalog/products/';
export const GET_CATALOG_FILTERS = '/catalog/filters/';
export const GET_CATALOG_VIEWED = '/catalog/viewed/';
export const GET_CATALOG_PRODUCTS_BY_CODE = (code: string) => `${GET_CATALOG_PRODUCTS}${code}/`;
export const GET_CATALOG_FILTERS_BY_CODE = (code: string) => `${GET_CATALOG_FILTERS}${code}/`;

// compare
export const COMPARE = '/compare/';
export const GET_COMPARE = '/compare/get/';
export const ADD_COMPARE = '/compare/add/';
export const DELETE_COMPARE = '/compare/delete/';
export const CLEAR_COMPARE = '/compare/clear/';

// contact
export const GET_CONTACT_CITIES = '/contact/cities/';
export const GET_CONTACT_INFO = '/contact/info/';
export const GET_GENERAL_SESS_ID = '/general/sessid/';

// form
export const ADD_FORM_CONTACT = '/form/contact/';
export const ADD_FORM_CALLBACK = '/form/callback/';
export const ADD_FORM_MANAGER = '/form/manager/';
export const ADD_FORM_TESTING = '/form/testing/';
export const ADD_FORM_VACANCY = '/form/vacancy/';
export const ADD_FORM_CONSULT = '/form/consult/';
export const ADD_FORM_FAST = '/form/fast/';

// order
export const ORDER = '/basket/order/';
export const GET_ORDER = '/order/';
export const ADD_ORDER = '/order/add/';
export const ADD_ORDER_LIMITED = '/order/limitedAdd/';
export const GET_ORDER_RESULT = '/order/result/';
export const GET_OREDER_LIMITED_CHECK = '/order/limitedCheck/';
export const GET_OREDER_CHECK = '/order/check/';

// orders history
export const ORDER_HISTORY = '/cabinet/order-history/';
export const GET_ORDERS_HISTORY = '/personal/orders/history/';
export const CANCEL_ORDER = '/personal/orders/cancel/';
export const SEND_MESSAGE_TO_MANAGER = '/personal/orders/sendMessage/';

// product
export const GET_PRODUCT = '/catalog/product/';
export const GET_PRODUCT_BY_CODE = (code: string) => `${GET_PRODUCT}${code}/`;
export const PRODUCT_SEARCH = '/catalog/search/';
export const GET_PRODUCTS_INFO_BY_ID = '/product/id/';

export const GET_PRODUCT_REMAINS = (id: number) => `catalog/priceDetails/?productId=${id}`;

// search
export const SEARCH = '/search/';
export const SEARCH_FAST = '/search/fast/';

// cabinet
export const CABINET = '/cabinet/';
export const FAVOURITE = '/cabinet/delayed-products/';
export const FAVOURITE_NOT_AUTH = '/delayed-products/';
export const USERS = '/cabinet/dannue-polzovatelya/';

// personal
export const CHANGE_PASSWORD = '/personal/users/changePassword/';
export const GET_COMPANIES = '/personal/companies/';
export const ADD_COMPANY = '/personal/companies/add/';
export const EDIT_COMPANY = '/personal/companies/edit/';
export const DELETE_COMPANY = '/personal/companies/delete/';
export const SET_MAIN_COMPANY = '/personal/companies/setMainCompany/';
export const GET_PERSONAL_USER = '/personal/user/';
export const GET_PERSONAL_USERS = '/personal/users/';
export const ADD_USER = '/personal/users/add/';
export const EDIT_USER = '/personal/users/edit/';
export const DELETE_USER = '/personal/users/delete/';
export const SET_MAIN_USER = '/personal/users/setMainUser/';

// cabinet-widgets
export const WIDGETS = '/personal/widgets/';
export const SET_WIDGETS = '/personal/widgets/set/';
export const GET_WIDGETS = '/personal/widgets/get/';
