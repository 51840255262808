import displaySvg from 'svg/sliders/oborudovanie/display.vue';
import keySvg from 'svg/sliders/oborudovanie/key.vue';
import plugSvg from 'svg/sliders/oborudovanie/plug.vue';
import progSvg from 'svg/sliders/oborudovanie/prog.vue';
import psuSvg from 'svg/sliders/oborudovanie/psu.vue';

export const serviceList = [
  {
    svg: psuSvg,
    name: 'Источники бесперебойного питания',
  },
  {
    svg: keySvg,
    name: 'Клавиатуры\nи мыши',
  },
  {
    svg: plugSvg,
    name: 'Провода\nи шлейфы',
  },
  {
    svg: progSvg,
    name: 'Программное обеспечение',
  },
  {
    svg: displaySvg,
    name: 'Мониторы\nдля серверов',
  },
];
export const equipmentList = [
  {
    count: 152,
    svg: psuSvg,
    name: 'Источники бесперебойного питания',
  },
  {
    count: 12,
    svg: keySvg,
    name: 'Клавиатуры\nи мыши',
  },
  {
    count: 2,
    svg: plugSvg,
    name: 'Провода\nи шлейфы',
  },
  {
    count: 100,
    svg: progSvg,
    name: 'Программное обеспечение',
  },
  {
    count: 42,
    svg: displaySvg,
    name: 'Мониторы\nдля серверов',
  },
];

export const reviewItems = [
  {
    id: 1,
    title:
      'Будучи компанией общественного здравоохранения с ограниченными ресурсами, нам необходимо сотрудничать с тем поставщиком кибербезопасности, который предоставляет решение с низкими текущими расходами и хорошим возвратом на инвестиций. ',
    author: {
      name: 'Марк',
      familyName: 'Апчерч',
      engName: '(Marc Upchurch)',
    },
    authorPosition:
      'Директор по информационной безопасности Департамента общественного здравоохранения города и округа Сан-Франциско',
  },
];
