<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6421 12.1875H8.78125C8.78125 12.1875 8.78125 12.5023 8.78125 12.8906C8.78125 13.279 8.78125 13.5938 8.78125 13.5938H15.6421C15.8225 13.5938 15.9687 13.279 15.9687 12.8906C15.9688 12.5023 15.8225 12.1875 15.6421 12.1875Z"
      fill="#252525"
    />
    <path
      d="M7.1875 12.1875H0.3267C0.146267 12.1875 0 12.5023 0 12.8906C0 13.279 0.146267 13.5938 0.3267 13.5938H7.1875C7.1875 13.5938 7.1875 13.5938 7.1875 12.9219C7.1875 12.1875 7.1875 12.1875 7.1875 12.1875Z"
      fill="#252525"
    />
    <path
      d="M15.6421 7.3125H8.78125C8.78125 7.3125 8.78125 7.62732 8.78125 8.01564C8.78125 8.40396 8.78125 8.71875 8.78125 8.71875H15.6421C15.8225 8.71875 15.9687 8.40396 15.9687 8.01564C15.9688 7.62732 15.8225 7.3125 15.6421 7.3125Z"
      fill="#252525"
    />
    <path
      d="M7.1875 7.3125H0.3267C0.146267 7.3125 0 7.62732 0 8.01564C0 8.40396 0.146267 8.71875 0.3267 8.71875H7.1875C7.1875 8.71875 7.1875 8.71875 7.1875 8.04688C7.1875 7.3125 7.1875 7.3125 7.1875 7.3125Z"
      fill="#252525"
    />
    <path
      d="M15.6421 2.4375H8.78125C8.78125 2.4375 8.78125 2.75232 8.78125 3.14064C8.78125 3.52896 8.78125 3.84375 8.78125 3.84375H15.6421C15.8225 3.84375 15.9687 3.52896 15.9687 3.14064C15.9688 2.75232 15.8225 2.4375 15.6421 2.4375Z"
      fill="#252525"
    />
    <path
      d="M7.1875 2.4375H0.3267C0.146267 2.4375 0 2.75232 0 3.14064C0 3.52896 0.146267 3.84375 0.3267 3.84375H7.1875C7.1875 3.84375 7.1875 3.84375 7.1875 3.17188C7.1875 2.4375 7.1875 2.4375 7.1875 2.4375Z"
      fill="#252525"
    />
  </svg>
</template>
