<template>
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5 18.5C22.5 17.3954 23.3954 16.5 24.5 16.5H40.5C41.6046 16.5 42.5 17.3954 42.5 18.5C42.5 19.6046 41.6046 20.5 40.5 20.5H24.5C23.3954 20.5 22.5 19.6046 22.5 18.5Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5 26.5C22.5 25.3954 23.3954 24.5 24.5 24.5H40.5C41.6046 24.5 42.5 25.3954 42.5 26.5C42.5 27.6046 41.6046 28.5 40.5 28.5H24.5C23.3954 28.5 22.5 27.6046 22.5 26.5Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.5 6.5C50.7091 6.5 52.5 8.29086 52.5 10.5V54.5C52.5 56.7091 50.7091 58.5 48.5 58.5H16.5C14.2909 58.5 12.5 56.7091 12.5 54.5V10.5C12.5 8.29086 14.2909 6.5 16.5 6.5H48.5ZM48.5 54.5V10.5H16.5V54.5H48.5Z"
      :fill="color"
    />
    <path
      d="M32.5 48.5C34.1569 48.5 35.5 47.1569 35.5 45.5C35.5 43.8431 34.1569 42.5 32.5 42.5C30.8431 42.5 29.5 43.8431 29.5 45.5C29.5 47.1569 30.8431 48.5 32.5 48.5Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    color: {type: String, required: false, default: '#4B6286'},
  },
};
</script>

<style lang="scss">
path {
  transition: 0.3s fill;
}
</style>
