<template>
  <div class="container">
    <div v-if="!isLoading" class="not-found">
      <div class="not-found__main">
        <h2 class="not-found__title">Увы, страница не найдена</h2>
        <div class="not-found__text">
          <p>
            К сожалению, вы зашли на несуществующую страницу по старой ссылке или ввели неправильный
            адрес.
          </p>
          <p>Попробуйте проверить ссылку или вернитесь на главную страницу.</p>
        </div>
        <base-button text="Вернуться на главную" url="/"></base-button>
      </div>
      <div class="not-found__digits">404</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/404.scss';
import {onMounted, ref} from '#imports';
import BaseButton from 'components/Base/Button.vue';

// TODO: потестить без error.statusCode, мб смысла ноль
// const error = useError();
const isLoading = ref(true);

onMounted(() => {
  isLoading.value = false;
});
</script>
