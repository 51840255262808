<template>
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.5 10.5C20.3497 10.5 10.5 20.3497 10.5 32.5C10.5 44.6503 20.3497 54.5 32.5 54.5C44.6503 54.5 54.5 44.6503 54.5 32.5C54.5 20.3497 44.6503 10.5 32.5 10.5ZM6.5 32.5C6.5 18.1406 18.1406 6.5 32.5 6.5C46.8594 6.5 58.5 18.1406 58.5 32.5C58.5 46.8594 46.8594 58.5 32.5 58.5C18.1406 58.5 6.5 46.8594 6.5 32.5Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.5 26.5C29.1863 26.5 26.5 29.1863 26.5 32.5C26.5 35.8137 29.1863 38.5 32.5 38.5C35.8137 38.5 38.5 35.8137 38.5 32.5C38.5 29.1863 35.8137 26.5 32.5 26.5ZM22.5 32.5C22.5 26.9772 26.9772 22.5 32.5 22.5C38.0228 22.5 42.5 26.9772 42.5 32.5C42.5 38.0228 38.0228 42.5 32.5 42.5C26.9772 42.5 22.5 38.0228 22.5 32.5Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.5 32.5C38.5 31.3954 39.3954 30.5 40.5 30.5H56.5C57.6046 30.5 58.5 31.3954 58.5 32.5C58.5 33.6046 57.6046 34.5 56.5 34.5H40.5C39.3954 34.5 38.5 33.6046 38.5 32.5Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.8896 14.1108C51.6707 14.8919 51.6707 16.1582 50.8896 16.9392L39.5646 28.2642C38.7836 29.0453 37.5172 29.0453 36.7362 28.2642C35.9551 27.4832 35.9551 26.2169 36.7362 25.4358L48.0612 14.1108C48.8422 13.3298 50.1086 13.3298 50.8896 14.1108Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    color: {type: String, required: false, default: '#4B6286'},
  },
};
</script>

<style lang="scss">
path {
  transition: 0.3s fill;
}
</style>
