// @ts-expect-error no typings
import YmapPlugin from 'vue-yandex-maps';
import {defineNuxtPlugin} from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(YmapPlugin, {
    apiKey: '',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1',
  });
});
