import {defineStore} from 'pinia';
import {ref} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {
  ADD_COMPANY,
  ADD_USER,
  CHANGE_PASSWORD,
  DELETE_COMPANY,
  DELETE_USER,
  EDIT_COMPANY,
  EDIT_USER,
  GET_COMPANIES,
  GET_PERSONAL_USER,
  GET_PERSONAL_USERS,
  SET_MAIN_COMPANY,
  SET_MAIN_USER,
} from '@/composables/useURL';
import type {IPersonalCompanyResponse, IPersonalUserResponse} from '@/types/store/personal';

// TODO body types

const addCompany = async (body: unknown) => {
  const data = (await useAuthFetch(ADD_COMPANY, {
    method: 'post',
    body,
  })) as {id: number};
  return data.id;
};

const editCompany = async (body: unknown) => {
  const data = (await useAuthFetch(EDIT_COMPANY, {
    method: 'post',
    body,
  })) as {id: number};
  return data.id;
};

const setMainCompany = async (companyId: number) => {
  await useAuthFetch(SET_MAIN_COMPANY, {
    query: {id: companyId},
  });
};

const changeUserPass = async (body: unknown) => {
  const data = (await useAuthFetch(CHANGE_PASSWORD, {
    method: 'post',
    body,
  })) as {status?: boolean};
  return data.status;
};

export const usePersonalStore = defineStore('personal', () => {
  const bonuses = ref(0);
  const id = ref<number>();
  const isMain = ref(false);
  const users = ref<IPersonalUserResponse[]>([]);
  const companies = ref<IPersonalCompanyResponse[]>([]);

  const setLocalMainUser = (userId: number) => {
    users.value = users.value.map((user) => {
      user.isMain = user.id === userId;
      return user;
    });
  };

  const getCompanies = async () => {
    const data = (await useAuthFetch(GET_COMPANIES, {})) as IPersonalCompanyResponse[];
    companies.value = data;
  };

  const deleteCompany = async (companyId: number) => {
    await useAuthFetch(DELETE_COMPANY, {
      query: {id: companyId},
    });
    companies.value = companies.value.filter((el) => el.id !== companyId);
  };

  const getUserInfo = async () => {
    const response = (await useAuthFetch(GET_PERSONAL_USER)) as IPersonalUserResponse;
    isMain.value = response.isMain;
    id.value = response.id;
    bonuses.value = response.bonuses || 0;
  };

  const getUsers = async () => {
    const data = (await useAuthFetch(GET_PERSONAL_USERS)) as IPersonalUserResponse[];
    users.value = data;
  };

  const addUser = async (body: unknown) => {
    await useAuthFetch(ADD_USER, {
      method: 'post',
      body,
    });
    users.value.push(body);
  };

  const editUser = async (body: unknown) => {
    await useAuthFetch(EDIT_USER, {
      method: 'post',
      body,
    });
    users.value = users.value.map((el) => {
      if (el.id === body.id) {
        return body;
      }
      return el;
    });
  };

  const deleteUser = async (userId: number) => {
    await useAuthFetch(DELETE_USER, {
      query: {id: userId},
    });
    users.value = users.value.filter((el) => el.id !== userId);
  };

  const setMainUser = async (userId: number) => {
    await useAuthFetch(SET_MAIN_USER, {
      query: {id: userId},
    });
    setLocalMainUser(userId);
  };

  return {
    bonuses,
    id,
    isMain,
    users,
    companies,

    getCompanies,
    addCompany,
    editCompany,
    deleteCompany,
    setMainCompany,
    getUserInfo,
    getUsers,
    addUser,
    editUser,
    deleteUser,
    setMainUser,
    changeUserPass,
  };
});
