import axios, {type AxiosInstance} from 'axios';
import {useRuntimeConfig} from '#imports';

let instance: AxiosInstance | null = null;
export const useAxiosInstance = () => {
  const {API_BASE_URL} = useRuntimeConfig().public;
  if (!instance) {
    instance = axios.create({
      baseURL: API_BASE_URL,
    });
    instance.interceptors.response.use((response) => {
      return response.data;
    });
  }
  return instance;
};
