import {defineStore} from 'pinia';
import {computed, ref} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {
  ADD_PRODUCT_TO_FAVORITE_LIST,
  CREATE_FAVORITE_LIST,
  GET_FAVORITE_LISTS,
  GET_FAVORITE_PRODUCTS_BY_LIST,
  GET_LISTS_BY_PRODUCT,
  GET_PRODUCTS_INFO_BY_ID,
  GET_PRODUCTS_IN_FAVORITE,
  REMOVE_PRODUCT_FAVORITE_LIST,
  REMOVE_PRODUCT_FROM_FAVORITE_LIST,
} from '@/composables/useURL';
import type {
  IFavorite,
  IFavoriteProduct,
  IResProductFavorite,
} from '@/types/pages/cabinet/delayed-products';
import type {IListByProduct, ISelected, ISort} from '@/types/store/cabinetGoods';
import type {IFilterItem, IFilters, IProductDefault} from '@/types/store/products';

const getProductItemById = async (productId: number | string) => {
  const opt = {
    query: {
      id: productId,
    },
  };
  const res: IProductDefault = (await useAuthFetch(
    GET_PRODUCTS_INFO_BY_ID,
    opt,
  )) as IProductDefault;
  return res;
};

export const useCabinetGoodsStore = defineStore('cabinetGoods', () => {
  const name = ref('');
  const text = ref('');
  const maxPage = ref(0);
  const currentPage = ref(1);
  const currentPageSize = ref(15);
  const isLoaded = ref(false);
  const isFetchFilters = ref(false);
  const isFetchCollection = ref(false);
  const listForPrintIsFetched = ref(false);
  const id = ref<number>();
  const count = ref<number>();
  const generalListId = ref<number>();
  const currentList = ref<IFavorite>();
  const favoriteList = ref<IFavorite[]>([]);
  const sortList = ref<IFavoriteProduct[]>([]);
  const productsInFavorite = ref<number[]>([]);
  const favoriteIdList = ref<(string | number)[]>([]);
  const productsList = ref<IFavoriteProduct[]>([]);
  const listForPrint = ref<IFavoriteProduct[][]>([]);
  const filter = ref<IFilters>({minPrice: 0, maxPrice: 0, sections: [], vendors: []});
  const listsByProduct = ref<IListByProduct>({favoriteLists: [], isGeneralList: null});
  const selected = ref<ISelected>({
    by: 'price',
    sort: 'asc',
    minPrice: 0,
    maxPrice: 0,
    sections: [],
    vendors: [],
  });

  const getFavoriteListsByProduct = computed(() => listsByProduct.value.favoriteLists);
  const getCountProductsInFavorite = computed(() => productsInFavorite.value.length);
  const getCountFavoriteLists = computed(() => favoriteList.value.length);
  const getCountProductsInCurrentFavoriteList = computed(() => sortList.value.length);
  const isProductInList = (id_: number) => productsInFavorite.value.includes(id_);
  const getSelectedFiltersLength = (keyFilter: 'vendors' | 'sections') =>
    selected.value[keyFilter].length;

  const changeFiltersItems = (keyFilter: 'vendors' | 'sections', items: IFilterItem[]) => {
    selected.value[keyFilter] = items;
  };

  const removeFiltersIds = (keyFilter: 'vendors' | 'sections') => {
    selected.value[keyFilter] = [];
  };

  const getListForPrint = async (listId: number | string) => {
    currentPage.value = 1;
    currentPageSize.value = 12;
    const getList = async () => {
      const res: IResProductFavorite = (await useAuthFetch(GET_FAVORITE_PRODUCTS_BY_LIST, {
        query: {
          listId,
          by: selected.value.by,
          sort: selected.value.sort,
          page: currentPage.value,
          pageSize: currentPageSize.value,
        },
      })) as IResProductFavorite;

      if (!res.products || !res) {
        return;
      }

      listForPrintIsFetched.value = true;
      listForPrint.value.push(res.products);
      maxPage.value = res.maxPage;
      name.value = res.name;
    };
    await getList();
    for (let i = 0; i < maxPage.value - 1; i++) {
      currentPage.value += 1;
      // eslint-disable-next-line no-await-in-loop
      await getList();
    }
  };

  const clearSelected = () => {
    selected.value.by = 'price';
    selected.value.sort = 'asc';
    selected.value.minPrice = 0;
    selected.value.maxPrice = 0;
    selected.value.sections = [];
    selected.value.vendors = [];
  };

  const getProducts = async (
    id_?: number | string,
    by?: 'price' | 'quantity',
    sort?: 'asc' | 'desc',
  ) => {
    let listId = id_;
    if (!listId && Boolean(currentList.value)) listId = currentList.value?.id;
    if (!listId && Boolean(generalListId.value)) listId = generalListId.value;

    const sectionsId = selected.value.sections.length
      ? selected.value.sections.map((item) => item.id)
      : [];
    const vendorsId = selected.value.vendors.length
      ? selected.value.vendors.map((item) => item.id)
      : [];

    // Если проиcходит фетч фильтров то оставляем тока этот фетч
    if (!isFetchFilters.value) {
      isFetchCollection.value = true;
    }

    currentList.value = favoriteList.value.find((item) => item.id === id_) || currentList.value;

    if (currentList.value?.count === 0) {
      productsList.value = [];
      sortList.value = [];
      count.value = 0;
      isFetchCollection.value = false;
      isFetchFilters.value = false;
      filter.value.maxPrice = 0;
      filter.value.minPrice = 0;
      filter.value.sections = [];
      filter.value.vendors = [];
      clearSelected();
      return;
    }

    const res: IResProductFavorite = (await useAuthFetch(GET_FAVORITE_PRODUCTS_BY_LIST, {
      query: {
        listId: listId || null,
        by: by || selected.value.by,
        sort: sort || selected.value.sort,
        page: currentPage.value,
        pageSize: currentPageSize.value,
      },
      method: 'POST',
      body: {
        filters: {
          minPrice: selected.value.minPrice,
          maxPrice: selected.value.maxPrice,
          sectionsId,
          vendorsId,
        },
      },
    })) as IResProductFavorite;

    isFetchCollection.value = false;
    isFetchFilters.value = false;
    if (!res.id) {
      return;
    }
    id.value = res.id;
    name.value = res.name;
    text.value = res.text;
    productsList.value = res.products;
    sortList.value = res.products ? [...res.products] : [];
    maxPage.value = res.maxPage;
    filter.value = res.filters || filter.value;
    count.value = res.count;
    // selected.value.minPrice = res.filters.minPrice;
    // selected.value.maxPrice = res.filters.maxPrice;
  };

  const setCurrentPageSize = (size: number) => {
    isFetchFilters.value = true;
    currentPageSize.value = size;
    currentPage.value = 1;
    if (currentList.value === null) {
      return;
    }
    void getProducts(currentList.value?.id);
  };

  const setCurrentPage = (page: number) => {
    isFetchFilters.value = true;
    currentPage.value = page;
    if (currentList.value === null) {
      return;
    }
    void getProducts(currentList.value?.id);
  };

  const getProductsInFavorite = async () => {
    const res: number[] = (await useAuthFetch(GET_PRODUCTS_IN_FAVORITE, {})) as number[];
    productsInFavorite.value = res;
  };

  const getListsByProductId = async (id_: number) => {
    const opt = {
      query: {
        productId: id_,
      },
    };
    const res: IListByProduct = (await useAuthFetch(GET_LISTS_BY_PRODUCT, opt)) as IListByProduct;
    if (!res.isGeneralList) {
      return;
    }
    generalListId.value = res.isGeneralList.id;
    listsByProduct.value.isGeneralList = res.isGeneralList;

    if (res) {
      listsByProduct.value.favoriteLists = res.favoriteLists || [];
    }
  };

  const getList = async () => {
    const res: IFavorite[] = (await useAuthFetch(GET_FAVORITE_LISTS, {})) as IFavorite[];
    isLoaded.value = true;

    favoriteList.value = res;
    if (res.length) {
      await getProducts(res[0]?.id);
    }
  };

  const removeList = async (listId: number) => {
    const opt = {
      query: {
        listId,
      },
    };
    const res = (await useAuthFetch(REMOVE_PRODUCT_FAVORITE_LIST, opt)) as unknown;
    if (res?.status === true) {
      favoriteList.value = favoriteList.value.filter((item) => item.id !== listId);
    }
    currentList.value = favoriteList.value[0];
  };

  const createNewList = async (name_: string, id_?: number) => {
    const opt = {
      query: {
        name: name_,
        productId: id_ || null,
      },
    };
    const res: IFavorite = (await useAuthFetch(CREATE_FAVORITE_LIST, opt)) as IFavorite;
    favoriteList.value = [{...res}, ...favoriteList.value];
  };

  const createNewListWithoutProduct = async (name_: string) => {
    const opt = {
      query: {
        name: name_,
      },
    };

    const res: IFavorite = (await useAuthFetch(CREATE_FAVORITE_LIST, opt)) as IFavorite;
    favoriteList.value = [...favoriteList.value, {...res}];
    currentList.value = favoriteList.value[0];
  };

  const removeProduct = async (idProduct: number) => {
    if (!currentList.value) {
      return;
    }
    const opt = {
      query: {
        productId: idProduct,
        listId: currentList.value.id,
      },
    };

    const {status} = await useAuthFetch(REMOVE_PRODUCT_FROM_FAVORITE_LIST, opt);

    if (status) {
      sortList.value = sortList.value.filter((item) => item.id !== idProduct);
      currentList.value.count -= 1;
      productsList.value = productsList.value.filter((item) => item.id !== idProduct);
      productsInFavorite.value = productsInFavorite.value.filter((id_) => id_ !== idProduct);
    }
  };

  const addProduct = async (idProduct: number, idList?: number) => {
    const opt = {
      query: {
        productId: idProduct,
        listId: idList || '',
      },
    };

    const {status} = (await useAuthFetch(ADD_PRODUCT_TO_FAVORITE_LIST, opt)) as unknown;
    if (status) {
      productsInFavorite.value.push(idProduct);
      if (!idList) {
        await getList();
      }
      void getProducts(idList);
    }
  };

  const changeRange = (key: 'minPrice' | 'maxPrice', number: number) => {
    selected.value[key] = number;
    // getProducts(currentList.value.id, selected.value)
  };

  const changeSort = (val: ISort) => {
    selected.value.by = val.by;
    selected.value.sort = val.value;
    isFetchFilters.value = true;
    if (!currentList.value) {
      return;
    }
    void getProducts(currentList.value.id);
  };

  const changeFilters = () => {
    isFetchFilters.value = true;
    if (!currentList.value) {
      return;
    }
    void getProducts(currentList.value.id);
  };

  const clearSelectedFilters = () => {
    isFetchFilters.value = true;
    clearSelected();
    if (!currentList.value) {
      return;
    }
    void getProducts(currentList.value.id);
  };

  return {
    name,
    text,
    maxPage,
    currentPage,
    currentPageSize,
    isLoaded,
    isFetchFilters,
    isFetchCollection,
    listForPrintIsFetched,
    id,
    count,
    generalListId,
    currentList,
    favoriteList,
    sortList,
    productsInFavorite,
    favoriteIdList,
    productsList,
    listForPrint,
    filter,
    listsByProduct,
    selected,

    getFavoriteListsByProduct,
    getCountProductsInFavorite,
    getCountFavoriteLists,
    getCountProductsInCurrentFavoriteList,
    isProductInList,
    getSelectedFiltersLength,

    clearSelectedFilters,
    changeSort,
    changeRange,
    changeFilters,
    removeList,
    createNewList,
    createNewListWithoutProduct,
    removeProduct,
    addProduct,
    getProductItemById,
    getProductsInFavorite,
    getListsByProductId,
    getList,
    setCurrentPageSize,
    setCurrentPage,
    changeFiltersItems,
    clearSelected,
    removeFiltersIds,
    getListForPrint,
    getProducts,
  };
});
