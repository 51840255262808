import monitorImg1440 from 'assets/images/catalog/monitor1440.png';
import serverImg1440 from 'assets/images/catalog/server1440.jpg';
import serverModificationImg1440 from 'assets/images/catalog/serverModification1440.jpg';
import {useMultipleItems} from '@/composables/useUtils';
import {equipmentList} from '@/services/slider';
import disk from 'components/catalog/options/items/disk.vue';
import vendor from 'components/catalog/options/items/vendor.vue';
import cardsSvg from 'svg/catalog/cards.vue';
import dellCompanySvg from 'svg/catalog/dellCompany.vue';
import groupsSvg from 'svg/catalog/groups.vue';
import listsSvg from 'svg/catalog/lists.vue';
import {diskOptions, vendorOptions} from './options';

export const greySlideList = {
  slideList: useMultipleItems(equipmentList, 3),
};

export const labelListData = [
  {text: 'Вендор', value: vendor, options: vendorOptions},
  {text: 'Процессор', value: 'processor'},
  {text: 'Память', value: 'ram'},
  {text: 'Диск', value: disk, options: diskOptions},
  {text: 'Тип RAID', value: 'raidType'},
  {text: 'Питание', value: 'power'},
  {text: 'Управление', value: 'control'},
  {text: 'Гарантия', value: 'warranty'},
];

export const showedList = [
  {text: '30', value: 30},
  {text: '50', value: 50},
  {text: 'Всё', value: 100000},
];
export const shortShowedList = [
  {text: '5', value: 5},
  {text: '15', value: 15},
  {text: 'Всё', value: 100000},
];

const typeList = [
  {
    text: 'Группами',
    svg: groupsSvg,
    value: 'groups',
  },
  {
    text: 'Товарами',
    svg: cardsSvg,
    value: 'cards',
  },
];
const listTypeItem = {
  text: 'Списком',
  svg: listsSvg,
  value: 'lists',
};
export const viewTypeListData = (hasConfiguration = false) =>
  hasConfiguration ? [...typeList, listTypeItem] : typeList;

const specifications = [
  '1x16GB DDR4-2666 RDIMM DR',
  'HDD',
  'P408i 2GB NV RAID 0,1,10,5,50,6,60',
  'No DVD',
  'Easy Install Rail Kit',
  '3Y GLW NBD (3/3/3)',
  'No Bezel',
  'SD Module',
  'SD Module',
  'SD Module',
];
const modifName = 'Сервер dell poweredge t20';
const modifDescription =
  'Cервер Dell PowerEdge t20 объединяет высокую емкость внутренней системы хранения данных и производительность в корпусе Mini-Tower, обеспечивая эффективную и бесперебойную работу.';
const itemName = 'Монитор ViewSonic VA1903';

const itemDescription =
  'ЖК-монитор с диагональю 24” тип матрицы экрана TFT E-IPS разрешение 1920х1200 (16:10) яркость 300 кд/м2';

const serversList = [
  {
    id: 1,
    name: modifName,
    description: modifDescription,
    bonuses: 4000,
    img: serverImg1440,
    vendor: {
      img: dellCompanySvg,
      code: 'hpe',
    },
    configurations: [
      {
        id: 1,
        img: serverModificationImg1440,
        name: "Сервер HPE ProLiant DL360 Gen10 (до 11x2,5'', 2U) 1xSilver",
        specifications,
        price: 51000,
        bonuses: 5000,
        available: {
          isSale: true,
          orderOnly: true,
        },
      },
      {
        id: 2,
        img: serverModificationImg1440,
        name: "Сервер HPE ProLiant DL360 Gen10 (до 11x2,5'', 2U) 1xSilver",
        specifications,
        price: 61000,
        bonuses: 6000,
        available: {
          isSale: true,
          orderOnly: false,
          stock: 4,
          tranzit: 30,
        },
      },
      {
        id: 3,
        img: serverModificationImg1440,
        name: "Сервер HPE ProLiant DL360 Gen10 (до 11x2,5'', 2U) 1xSilver",
        specifications,
        available: {
          isSale: false,
        },
      },
      {
        id: 4,
        img: serverModificationImg1440,
        name: "Сервер HPE ProLiant DL360 Gen10 (до 11x2,5'', 2U) 1xSilver",
        specifications,
        price: 61000,
        bonuses: 6000,
        available: {
          isSale: true,
          orderOnly: false,
          stock: 4,
          tranzit: 30,
        },
      },
    ],
  },
  {
    id: 2,
    img: monitorImg1440,
    name: itemName,
    description: itemDescription,
    price: 12500,
    bonuses: 5000,
    available: {
      isSale: true,
      orderOnly: true,
    },
  },
  {
    id: 3,
    img: monitorImg1440,
    name: itemName,
    description: itemDescription,
    price: 11000,
    bonuses: 4500,
    available: {
      isSale: true,
      orderOnly: false,
      stock: 4,
      tranzit: 30,
    },
  },
  {
    id: 4,
    img: monitorImg1440,
    name: itemName,
    description: itemDescription,
    available: {
      isSale: false,
    },
  },
];

export const serversListData = useMultipleItems(serversList, 10);
export const mainBreadcrumb = {name: 'Каталог', url: '/catalog'};
