import type {NitroFetchOptions} from 'nitropack';
import {useRuntimeConfig} from '#imports';
// eslint-disable-next-line import/no-cycle
import {useRootStore} from '@/stores/root';

const checkError = (res: unknown) => {
  const store = useRootStore();
  store.isError = res?.code === 404 || res?.data?.code === 404;
};

export const fetchFromApi = async <Response>(
  relativeUrl: string,
  options?: NitroFetchOptions<string>,
  ignoreErrors = false,
) => {
  const {API_BASE_URL} = useRuntimeConfig().public;
  const res = await $fetch<Response>(relativeUrl, {
    baseURL: String(API_BASE_URL),
    credentials: 'include',
    ...options,
  });
  if (!ignoreErrors) {
    checkError(res);
  }
  return res;
};

export const useMyFetch = async (
  url: string,
  options: NitroFetchOptions<string> = {},
): Promise<unknown> => {
  const {API_BASE_URL} = useRuntimeConfig().public;
  try {
    options.baseURL = API_BASE_URL;
    const res: unknown = await $fetch(url, options);
    checkError(res);
    return res;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const useAuthFetch = async (
  url: string,
  options: NitroFetchOptions<string> = {},
): Promise<unknown> => {
  const {API_BASE_URL} = useRuntimeConfig().public;
  try {
    options.baseURL = API_BASE_URL;
    options.credentials = 'include';
    const res: unknown = await $fetch(url, options);

    checkError(res);
    return res;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const downloadLink = (blob: string, label: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob as unknown);
  link.download = label;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const useDownloadLink = async (url: string, label: string): Promise<unknown> => {
  try {
    const blob = (await useAuthFetch(url)) as unknown;
    if (blob.code && blob.code === 400) {
      return blob.message;
    }
    document.createElement('a');
    downloadLink(blob, label);
  } catch (e) {
    console.log(e);
  }
  return undefined;
};

export const useFullDownloadLink = async (url: string, label: string) => {
  const blob = (await useAuthFetch(url)) as unknown;
  downloadLink(blob, label);
};

export const useClearDownloadLink = async (url: string) => {
  const {link} = (await useAuthFetch(url)) as unknown;
  window.open(link, '_blank');
};
