import {defineStore} from 'pinia';
import {computed, ref} from '#imports';
import {useAuthFetch} from '@/composables/useRequest';
import {
  GET_CATALOG_FILTERS_BY_CODE,
  GET_CATALOG_PRODUCTS_BY_CODE,
  GET_CATALOG_VIEWED,
} from '@/composables/useURL';
import {mainBreadcrumb} from '@/services/catalog';
import type {
  ICatalogFiltersByCodeResponse,
  IDataProducts,
  IProductResponse,
  IRelatedSectionResponse,
  ISidebarFilterResponse,
  ISubSectionResponse,
} from '@/types/store/catalog';
import {useRootStore} from './root';

type Filters = Record<string, string[]>;

export const useCatalogStore = defineStore('catalog', () => {
  const page = ref(1);
  const sort = ref('desc');
  const by = ref('quantity');
  const pageSize = ref(15);
  const query = computed(() => ({
    page: page.value,
    sort: sort.value,
    by: by.value,
    pageSize: pageSize.value,
  }));

  const maxPage = ref(1);

  const text = ref('');
  const isLoading = ref(true);
  const isFetched = ref(false);
  const filtersList = ref<ISidebarFilterResponse[]>([]);
  const viewedProducts = ref<IProductResponse[]>([]);
  const items = ref<IProductResponse[]>([]);

  const info = ref<Partial<IDataProducts>>({});
  const catalogBreadcrumbs = computed(() => [
    mainBreadcrumb,
    ...(info.value.breadcrumbsList || []),
  ]);

  const catalogCount = ref<number>();
  const activeFilters = ref<Filters>({});
  const relatedSections = ref<IRelatedSectionResponse[]>([]);
  const subSections = ref<ISubSectionResponse[]>([]);

  const clearInfo = () => {
    items.value = [];
  };

  const main = useRootStore();

  // TODO unused
  /*
  const setActiveFilters = (filters: Filters) => {
    for (const [key, value] of Object.entries(filters)) {
      if (value !== null && typeof value !== 'object') {
        filters[key] = [value];
      }
    }
    activeFilters.value = JSON.parse(JSON.stringify(filters));
  };
  */

  const setDefaultData = (data: IDataProducts, newMaxPage: number) => {
    if (data.catalogCount === 0) {
      throw createError({
        statusCode: 404,
        fatal: true,
      });
    }
    // TODO why separate vars?
    main.meta = data.meta;
    text.value = data.text;
    catalogCount.value = data.catalogCount || 0;
    relatedSections.value = data.relatedSections;
    subSections.value = data.subSections || [];
    info.value = data;
    maxPage.value = newMaxPage;
  };

  const setInfo = (data: IDataProducts, newMaxPage: number) => {
    items.value = data.products;
    setDefaultData(data, newMaxPage);
  };

  const setEmptyActiveFilters = (filters: ISidebarFilterResponse[]) => {
    filters.forEach((filter) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if(!activeFilters.value[filter.code]){
        activeFilters.value[filter.code] ||= [];
      }
    });
  };

  const setRouteFilters = (filters: any) =>{
    for (let key in filters) {
      activeFilters.value[key] = typeof filters[key] === 'string' ? [filters[key]] : filters[key]
    }
  }

  const getFilters = async (productCode: string, hasRouteFilters = false) => {
    const {sidebarFilters} = (await useAuthFetch(
      GET_CATALOG_FILTERS_BY_CODE(productCode),
    )) as ICatalogFiltersByCodeResponse;
    filtersList.value = sidebarFilters;
    setEmptyActiveFilters(sidebarFilters);
  };

  const getViewedProducts = async () => {
    const data = (await useAuthFetch(GET_CATALOG_VIEWED, {
      credentials: 'include',
    })) as IProductResponse[];
    viewedProducts.value = data;
  };

  // TODO unused
  /*
  const getMainData = async (productCode: string, firstQuery: Filters = {}) => {
    await getProducts(productCode, firstQuery);
    // await getViewedProducts();
    await getFilters(productCode);
  };
  */

  const getDefaultProducts = async (productCode: string) => {
    isLoading.value = true;
    const {data, maxPage: newMaxPage}: {data: IDataProducts; maxPage: number} = await useAuthFetch(
      GET_CATALOG_PRODUCTS_BY_CODE(productCode),
      {query: query.value, method: 'POST', body: activeFilters.value},
    ).finally(() => {
      isLoading.value = false;
      isFetched.value = true;
    });
    setInfo(data, newMaxPage);
  };

  const getProductsWithFilters = async (productCode: string) => {
    isLoading.value = true;
    clearInfo();
    const {data, maxPage: newMaxPage} = await useAuthFetch(
      GET_CATALOG_PRODUCTS_BY_CODE(productCode),
      {
        query: query.value,
        method: 'POST',
        body: activeFilters.value,
      },
    ).finally(() => {
      isLoading.value = false;
      isFetched.value = true;
    });
    setInfo(data, newMaxPage);
  };

  const updateInfo = (data: IDataProducts, newMaxPage: number) => {
    items.value = [...items.value, ...data.products];
    setDefaultData(data, newMaxPage);
  };

  const updateProducts = async (productCode: string) => {
    const {data, maxPage: newMaxPage} = await useAuthFetch(
      GET_CATALOG_PRODUCTS_BY_CODE(productCode),
      {
        query: query.value,
        method: 'POST',
        body: activeFilters.value,
      },
    );
    updateInfo(data, newMaxPage);
  };

  const changeActiveFilter = (filterCode: string, valueCode: string) => {
    page.value = 1;
    if (activeFilters.value[filterCode].includes(valueCode)) {
      activeFilters.value[filterCode] = activeFilters.value[filterCode].filter(
        (val) => val !== valueCode,
      );
      return;
    }
    activeFilters.value ||= {};
    activeFilters.value[filterCode] = [...activeFilters.value[filterCode], valueCode];
  };

  return {
    page,
    sort,
    by,
    pageSize,

    maxPage,
    text,
    isLoading,
    isFetched,
    filtersList,
    viewedProducts,
    items,

    catalogBreadcrumbs,

    catalogCount,
    activeFilters,
    relatedSections,
    subSections,

    getViewedProducts,
    changeActiveFilter,
    getDefaultProducts,
    getFilters,
    updateProducts,
    getProductsWithFilters,
    setRouteFilters
  };
});
