<template>
  <nuxt-layout>
    <not-found ></not-found>
  </nuxt-layout>
</template>

<script setup lang="ts">
import {useSeoMeta} from '#imports';
import NotFound from '@/components/NotFound.vue';


useSeoMeta({
  title: 'Страница не найдена',
});
</script>
