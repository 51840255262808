// TODO move to `utils`, this is not a composable

import {navigateTo, useRoute} from '#imports';
import {useBasketStore} from '@/stores/basket';

export const debounce = <T extends unknown[]>(func: (...args: T) => unknown, ms = 300) => {
  let timer: number | undefined;
  return (...args: T) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      func.apply(this, args);
    }, ms);
  };
};

const scrollAction = (selector: string) => {
  const headerH = document.querySelector(selector);
  if (headerH != null) {
    const {height} = headerH.getBoundingClientRect();
    document.documentElement.style.setProperty('--headerH', `${height + 10}px`);
    return height;
  }
  return 0;
};

const redirectPath = (from: string, to: string) => {
  const route = useRoute();
  const basePath = route.path.split('/')[1];

  if (basePath === from) {
    const path = route.path.replace(`/${from}`, `/${to}`);
    return navigateTo(path, {redirectCode: 301});
  }
  // eslint-disable-next-line unicorn/no-useless-undefined
  return undefined;
};

export const useHandleScroll = () => {
  if (window.innerWidth > 992) {
    scrollAction('.scrolled-bar');
  } else {
    scrollAction('.header__menu_mobile');
  }
};

export const isEmptyObject = (object?: object | null) => Object.keys(object || {}).length === 0;

export const useMultipleItems = (items: unknown[], count = 2) => {
  const modifiedItems = [];
  for (let index = 0; index < count; index++) {
    modifiedItems.push(...items);
  }
  return modifiedItems;
};

export const useSpacedPrice = (val: number) =>
  Math.trunc(val)
    .toString()
    // eslint-disable-next-line security/detect-unsafe-regex
    .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const useIsProductInBasket = (productId: number) => {
  const store = useBasketStore();
  return store.smallInfo.ids.includes(productId);
};

export const useButtonAddText = (productId: number) => {
  return useIsProductInBasket(productId) ? 'В корзине' : 'В корзину';
};

export const useAnimateNumber = (obj: Element, start: number, end: number, duration: number) => {
  if (start === end) {
    return;
  }

  let startTime: number | null = null;
  const step = (currentTime: number) => {
    if (!startTime) {
      startTime = currentTime;
    }
    const progress = Math.min((currentTime - startTime) / duration, 1);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    obj.innerHTML = useSpacedPrice(Math.floor(progress * (end - start) + start));
    if (progress < 1) {
      window.requestAnimationFrame(step);
    } else {
      window.cancelAnimationFrame(window.requestAnimationFrame(step));
    }
  };
  window.requestAnimationFrame(step);
};

const printChangeVision = (type: string) => {
  document.querySelectorAll('.not-print').forEach((e) => {
    (e as HTMLElement).style.display = type;
  });
};

export const usePrintPage = () => {
  printChangeVision('none');
  window.print();
  printChangeVision('');
};

export const useRedirectVendors = () => {
  void redirectPath('vendor', 'proizvoditeli');
  void redirectPath('vendors', 'proizvoditeli');
};
