import {defineNuxtPlugin} from '#imports';

const uniqueID = () => Math.floor(Math.random() * Date.now());

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('uid', {
    mounted(el) {
      el.setAttribute('id', uniqueID());
    },

    getSSRProps() {
      return {};
    },
  });
});
