import {useMultipleItems} from '@/composables/useUtils';
import certificateImg from 'images/vendor/certificate.jpg';
import AcerLogo from 'images/vendors/Acer.jpg';
import CommuniGateLogo from 'images/vendors/CommuniGate.jpg';
import DLinkLogo from 'images/vendors/D-link.jpg';
import hpLogo from 'images/vendors/hp.jpg';

const aboutText =
  'Коллеги из компании АСКОН, российский разработчик САПР, объявили об открытом бета-тестировании новой...';

const certificateText = 'Сертификат авторизованного партнёра HP Partner';

const certificate = {
  vendorID: 1,
  id: 1,
  img: certificateImg,
  text: certificateText,
};
const vendorsList = [
  {
    id: 1,
    name: 'HewlettPackard',
    about: aboutText,
    logo: hpLogo,
    certificate,
    url: '/vendors/hp/',
  },
  {
    id: 2,
    name: 'CommuniGate',
    about: aboutText,
    logo: CommuniGateLogo,
    certificate,
    url: '/vendors/hp/',
  },
  {
    id: 3,
    name: 'Acer',
    about: aboutText,
    logo: AcerLogo,
    certificate,
    url: '/vendors/hp/',
  },
  {
    id: 4,
    name: 'D-Link',
    about: aboutText,
    logo: DLinkLogo,
    certificate,
    url: '/vendors/hp/',
  },
];

export const vendorsListData = useMultipleItems(vendorsList, 5);

export const emptyVendorItem = {
  id: 1,
  name: 'Показать всё',
  isActive: false,
  value: 'all',
};
