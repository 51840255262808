import validate from "/home/bitrix/ext_www/t-mash.ru/tmash-front/node_modules/nuxt/dist/pages/runtime/validate.js";
import check_45auth_45global from "/home/bitrix/ext_www/t-mash.ru/tmash-front/middleware/checkAuth.global.ts";
import clear_45catalog_45global from "/home/bitrix/ext_www/t-mash.ru/tmash-front/middleware/clearCatalog.global.ts";
import clear_45store_45global from "/home/bitrix/ext_www/t-mash.ru/tmash-front/middleware/clearStore.global.ts";
import route_45global from "/home/bitrix/ext_www/t-mash.ru/tmash-front/middleware/route.global.ts";
export const globalMiddleware = [
  validate,
  check_45auth_45global,
  clear_45catalog_45global,
  clear_45store_45global,
  route_45global
]
export const namedMiddleware = {}