<template>
  <nuxt-layout>
    <nuxt-page></nuxt-page>
  </nuxt-layout>
</template>

<script setup lang="ts">
import {useEventListener} from '@vueuse/core';
import {
  computed,
  createError,
  onMounted,
  useAsyncData,
  useHead,
  useRoute,
  useSeoMeta,
  watch,
} from '#imports';
import {useRedirectVendors} from '@/composables/useUtils';
import {usePersonalStore} from '@/stores/personal';
import {useRootStore} from '@/stores/root';

const route = useRoute();
const rootStore = useRootStore();

const canonicalPath = computed(() => (route.path === '/' ? '' : route.path));

useSeoMeta({
  xUaCompatible: 'IE=edge',
  contentType: 'text/html; charset=utf-8',
  ogType: 'website',
  ogSiteName: 'Технологии и Машины - Оборудование и решения в сфере IT',
  ogLocale: 'ru_RU',
  ogLocaleAlternate: ['en_EN', 'ch_CH', 'de_DE'],
  ogImage: '/og-image.jpg',
  robots: 'index,follow',
  ogUrl: () => `https://www.t-mash.ru${route.fullPath}`,
});

useHead({
  htmlAttrs: {
    lang: 'ru',
  },
  link: () => [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.svg',
    },
    {
      rel: 'canonical',
      href: `https://www.t-mash.ru${canonicalPath.value}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '57x57',
      href: '/apple-touch-icon-57x57.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      href: '/apple-touch-icon-60x60.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      href: '/apple-touch-icon-72x72.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      href: '/apple-touch-icon-76x76.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      href: '/apple-touch-icon-114x114.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: '/apple-touch-icon-120x120.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      href: '/apple-touch-icon-144x144.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: '/apple-touch-icon-152x152.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon-180x180.png',
    },
  ],
});

await Promise.all([
  usePersonalStore().getUserInfo(),
  useAsyncData(() => rootStore.fetchMeta(route.path), {
    watch: [() => route.path],
  }),
]);

const handleResize = () => {
  rootStore.width = window.innerWidth;
};
onMounted(() => {
  handleResize();
  useEventListener(window, 'resize', handleResize);
});

watch(
  () => rootStore.isError,
  (isError) => {
    if (isError) {
      throw createError({
        statusCode: 404,
        fatal: true,
        statusMessage: 'Page Not Found',
      });
    }
  },
);

if (process.server) {
  useRedirectVendors();
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100px;
  height: 100px;
  background: #000000;
}
</style>
