import { default as _91page_93YEjjS0YWJAMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/[page].vue?macro=true";
import { default as changeqUrtjmgqfVMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/auth/change.vue?macro=true";
import { default as forgotO62Lv1s4pwMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/auth/forgot.vue?macro=true";
import { default as indexHMISHUoHUIMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/auth/index.vue?macro=true";
import { default as index79nv4SmoozMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/index.vue?macro=true";
import { default as _91orderId_938AXyLNhj2RMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/order/[orderId].vue?macro=true";
import { default as indexzaKed8cnwpMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/order/index.vue?macro=true";
import { default as _91basketCode_938LacGT6YC5Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/share/[basketCode].vue?macro=true";
import { default as dannue_45polzovatelyaDP2VICUYL1Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/dannue-polzovatelya.vue?macro=true";
import { default as indexj4lOCFk6sfMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/delayed-products/index.vue?macro=true";
import { default as faq_45item8WNVC1zUA9Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/history-bonus/faq-item.vue?macro=true";
import { default as indexJojB8Aj3sQMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/history-bonus/index.vue?macro=true";
import { default as table_45historya2W5pYHszCMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/history-bonus/table-history.vue?macro=true";
import { default as indexlOGYuzZd3sMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/index.vue?macro=true";
import { default as indexsjLT8tpiv0Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/my-companies/index.vue?macro=true";
import { default as indexARVDy7j7C8Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/my-tools/index.vue?macro=true";
import { default as bonus_45itemvSCUCkzQ09Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/bonus-item.vue?macro=true";
import { default as control_45groupSvQMySM1SbMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/control-group.vue?macro=true";
import { default as faq_45itembA3S816IXlMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/faq-item.vue?macro=true";
import { default as index13wDHYALOMMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/index.vue?macro=true";
import { default as input_45width_45iconvc6edQEyNLMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/input-width-icon.vue?macro=true";
import { default as indextveZ9oHqaTMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/order-history/index.vue?macro=true";
import { default as control_45groupKyeTa8AFItMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/control-group.vue?macro=true";
import { default as faq_45itemUssVSe2V1eMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/faq-item.vue?macro=true";
import { default as index05xlEwWMQIMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/index.vue?macro=true";
import { default as input_45width_45icon5MRqpu2A0lMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/input-width-icon.vue?macro=true";
import { default as uchastie_45itemMCz1wQrqXsMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/uchastie-item.vue?macro=true";
import { default as index06oLWBcja2Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/upravlenie-podpiskami/index.vue?macro=true";
import { default as aside1GFPSmurJwMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uslugi-i-services/aside.vue?macro=true";
import { default as indexUQHw8hz7KXMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uslugi-i-services/index.vue?macro=true";
import { default as item2HjwSKQCy0Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uslugi-i-services/item.vue?macro=true";
import { default as _91id_93QwvThyV9eCMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/catalog/[id].vue?macro=true";
import { default as _91productsCode_93UBJ442fXHiMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/catalog/[sectionCode]/[productsCode].vue?macro=true";
import { default as indexxsYa8zQbB3Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/catalog/index.vue?macro=true";
import { default as indexuOiG9RhEPMMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/compare/index.vue?macro=true";
import { default as contactsoV4QxweRrjMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/contacts.vue?macro=true";
import { default as indexvFhMdWlon2Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/delayed-products/index.vue?macro=true";
import { default as index3VaZSIUNVUMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/index.vue?macro=true";
import { default as indexjeJurck7SXMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/index.vue?macro=true";
import { default as _91newsCode_93WLC9CFEermMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/news/[newsCode].vue?macro=true";
import { default as indexMMuSGv5hBOMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/news/index.vue?macro=true";
import { default as search9F9YORz8QYMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/news/search.vue?macro=true";
import { default as _91articleCode_93nbcTYD8rLZMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/obzory/[articleCode].vue?macro=true";
import { default as indexygilMXGFVnMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/obzory/index.vue?macro=true";
import { default as searchIFsC6D4BeOMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/obzory/search.vue?macro=true";
import { default as _91articleCode_93zX8jNMViTWMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/stati/[articleCode].vue?macro=true";
import { default as indexjIgovweT8zMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/stati/index.vue?macro=true";
import { default as searchYpG7sGoaAjMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/stati/search.vue?macro=true";
import { default as _91articleCode_93IUlMaUZm9ZMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/video/[articleCode].vue?macro=true";
import { default as indexQ7FdiA3oKsMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/video/index.vue?macro=true";
import { default as searchWqhb5hgMATMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/video/search.vue?macro=true";
import { default as _91page_93Qjzxb1ik9cMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/kompaniya/[page].vue?macro=true";
import { default as indexSioaamhxvCMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/basket/index.vue?macro=true";
import { default as indexoYQgrc62lMMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/card/index.vue?macro=true";
import { default as index4hGNnEhh87Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/delayed-products/index.vue?macro=true";
import { default as _91orderId_93grPyzByG2YMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/order/[orderId].vue?macro=true";
import { default as indexiovkuaVA2wMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/order/index.vue?macro=true";
import { default as _91productCode_93YyA9YHd2ojMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/product/[categoryCode]/[productCode].vue?macro=true";
import { default as searcheGOU2xvWbQMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/product/search.vue?macro=true";
import { default as _91vendorCode_93mzZjpyuUhSMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/proizvoditeli/[vendorCode].vue?macro=true";
import { default as indexWAfaiCZnQXMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/proizvoditeli/index.vue?macro=true";
import { default as index2xPdtwvBQ3Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/reg/index.vue?macro=true";
import { default as indexzK4nDk6AlcMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/reg/success/index.vue?macro=true";
import { default as searchA2GYzyEIIqMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/search.vue?macro=true";
import { default as vacancies5m50Vd8JeGMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/vacancies.vue?macro=true";
import { default as _91vendorCode_93z2mheCOWZXMeta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/vendors/[vendorCode].vue?macro=true";
import { default as index4SLDJuOjJ9Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/vendors/index.vue?macro=true";
import { default as yandbtmKzWnnT2w30Meta } from "/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/yandbtm.vue?macro=true";
export default [
  {
    name: _91page_93YEjjS0YWJAMeta?.name ?? "page",
    path: _91page_93YEjjS0YWJAMeta?.path ?? "/:page()",
    meta: _91page_93YEjjS0YWJAMeta || {},
    alias: _91page_93YEjjS0YWJAMeta?.alias || [],
    redirect: _91page_93YEjjS0YWJAMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/[page].vue").then(m => m.default || m)
  },
  {
    name: changeqUrtjmgqfVMeta?.name ?? "auth-change",
    path: changeqUrtjmgqfVMeta?.path ?? "/auth/change",
    meta: changeqUrtjmgqfVMeta || {},
    alias: changeqUrtjmgqfVMeta?.alias || [],
    redirect: changeqUrtjmgqfVMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/auth/change.vue").then(m => m.default || m)
  },
  {
    name: forgotO62Lv1s4pwMeta?.name ?? "auth-forgot",
    path: forgotO62Lv1s4pwMeta?.path ?? "/auth/forgot",
    meta: forgotO62Lv1s4pwMeta || {},
    alias: forgotO62Lv1s4pwMeta?.alias || [],
    redirect: forgotO62Lv1s4pwMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/auth/forgot.vue").then(m => m.default || m)
  },
  {
    name: indexHMISHUoHUIMeta?.name ?? "auth",
    path: indexHMISHUoHUIMeta?.path ?? "/auth",
    meta: indexHMISHUoHUIMeta || {},
    alias: indexHMISHUoHUIMeta?.alias || [],
    redirect: indexHMISHUoHUIMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: index79nv4SmoozMeta?.name ?? "basket",
    path: index79nv4SmoozMeta?.path ?? "/basket",
    meta: index79nv4SmoozMeta || {},
    alias: index79nv4SmoozMeta?.alias || [],
    redirect: index79nv4SmoozMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_938AXyLNhj2RMeta?.name ?? "basket-order-orderId",
    path: _91orderId_938AXyLNhj2RMeta?.path ?? "/basket/order/:orderId()",
    meta: _91orderId_938AXyLNhj2RMeta || {},
    alias: _91orderId_938AXyLNhj2RMeta?.alias || [],
    redirect: _91orderId_938AXyLNhj2RMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/order/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexzaKed8cnwpMeta?.name ?? "basket-order",
    path: indexzaKed8cnwpMeta?.path ?? "/basket/order",
    meta: indexzaKed8cnwpMeta || {},
    alias: indexzaKed8cnwpMeta?.alias || [],
    redirect: indexzaKed8cnwpMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/order/index.vue").then(m => m.default || m)
  },
  {
    name: _91basketCode_938LacGT6YC5Meta?.name ?? "basket-share-basketCode",
    path: _91basketCode_938LacGT6YC5Meta?.path ?? "/basket/share/:basketCode()",
    meta: _91basketCode_938LacGT6YC5Meta || {},
    alias: _91basketCode_938LacGT6YC5Meta?.alias || [],
    redirect: _91basketCode_938LacGT6YC5Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/basket/share/[basketCode].vue").then(m => m.default || m)
  },
  {
    name: dannue_45polzovatelyaDP2VICUYL1Meta?.name ?? "cabinet-dannue-polzovatelya",
    path: dannue_45polzovatelyaDP2VICUYL1Meta?.path ?? "/cabinet/dannue-polzovatelya",
    meta: dannue_45polzovatelyaDP2VICUYL1Meta || {},
    alias: dannue_45polzovatelyaDP2VICUYL1Meta?.alias || [],
    redirect: dannue_45polzovatelyaDP2VICUYL1Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/dannue-polzovatelya.vue").then(m => m.default || m)
  },
  {
    name: indexj4lOCFk6sfMeta?.name ?? "cabinet-delayed-products",
    path: indexj4lOCFk6sfMeta?.path ?? "/cabinet/delayed-products",
    meta: indexj4lOCFk6sfMeta || {},
    alias: indexj4lOCFk6sfMeta?.alias || [],
    redirect: indexj4lOCFk6sfMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/delayed-products/index.vue").then(m => m.default || m)
  },
  {
    name: faq_45item8WNVC1zUA9Meta?.name ?? "cabinet-history-bonus-faq-item",
    path: faq_45item8WNVC1zUA9Meta?.path ?? "/cabinet/history-bonus/faq-item",
    meta: faq_45item8WNVC1zUA9Meta || {},
    alias: faq_45item8WNVC1zUA9Meta?.alias || [],
    redirect: faq_45item8WNVC1zUA9Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/history-bonus/faq-item.vue").then(m => m.default || m)
  },
  {
    name: indexJojB8Aj3sQMeta?.name ?? "cabinet-history-bonus",
    path: indexJojB8Aj3sQMeta?.path ?? "/cabinet/history-bonus",
    meta: indexJojB8Aj3sQMeta || {},
    alias: indexJojB8Aj3sQMeta?.alias || [],
    redirect: indexJojB8Aj3sQMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/history-bonus/index.vue").then(m => m.default || m)
  },
  {
    name: table_45historya2W5pYHszCMeta?.name ?? "cabinet-history-bonus-table-history",
    path: table_45historya2W5pYHszCMeta?.path ?? "/cabinet/history-bonus/table-history",
    meta: table_45historya2W5pYHszCMeta || {},
    alias: table_45historya2W5pYHszCMeta?.alias || [],
    redirect: table_45historya2W5pYHszCMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/history-bonus/table-history.vue").then(m => m.default || m)
  },
  {
    name: indexlOGYuzZd3sMeta?.name ?? "cabinet",
    path: indexlOGYuzZd3sMeta?.path ?? "/cabinet",
    meta: indexlOGYuzZd3sMeta || {},
    alias: indexlOGYuzZd3sMeta?.alias || [],
    redirect: indexlOGYuzZd3sMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexsjLT8tpiv0Meta?.name ?? "cabinet-my-companies",
    path: indexsjLT8tpiv0Meta?.path ?? "/cabinet/my-companies",
    meta: indexsjLT8tpiv0Meta || {},
    alias: indexsjLT8tpiv0Meta?.alias || [],
    redirect: indexsjLT8tpiv0Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/my-companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexARVDy7j7C8Meta?.name ?? "cabinet-my-tools",
    path: indexARVDy7j7C8Meta?.path ?? "/cabinet/my-tools",
    meta: indexARVDy7j7C8Meta || {},
    alias: indexARVDy7j7C8Meta?.alias || [],
    redirect: indexARVDy7j7C8Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/my-tools/index.vue").then(m => m.default || m)
  },
  {
    name: bonus_45itemvSCUCkzQ09Meta?.name ?? "cabinet-obmenat-bonusu-bonus-item",
    path: bonus_45itemvSCUCkzQ09Meta?.path ?? "/cabinet/obmenat-bonusu/bonus-item",
    meta: bonus_45itemvSCUCkzQ09Meta || {},
    alias: bonus_45itemvSCUCkzQ09Meta?.alias || [],
    redirect: bonus_45itemvSCUCkzQ09Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/bonus-item.vue").then(m => m.default || m)
  },
  {
    name: control_45groupSvQMySM1SbMeta?.name ?? "cabinet-obmenat-bonusu-control-group",
    path: control_45groupSvQMySM1SbMeta?.path ?? "/cabinet/obmenat-bonusu/control-group",
    meta: control_45groupSvQMySM1SbMeta || {},
    alias: control_45groupSvQMySM1SbMeta?.alias || [],
    redirect: control_45groupSvQMySM1SbMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/control-group.vue").then(m => m.default || m)
  },
  {
    name: faq_45itembA3S816IXlMeta?.name ?? "cabinet-obmenat-bonusu-faq-item",
    path: faq_45itembA3S816IXlMeta?.path ?? "/cabinet/obmenat-bonusu/faq-item",
    meta: faq_45itembA3S816IXlMeta || {},
    alias: faq_45itembA3S816IXlMeta?.alias || [],
    redirect: faq_45itembA3S816IXlMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/faq-item.vue").then(m => m.default || m)
  },
  {
    name: index13wDHYALOMMeta?.name ?? "cabinet-obmenat-bonusu",
    path: index13wDHYALOMMeta?.path ?? "/cabinet/obmenat-bonusu",
    meta: index13wDHYALOMMeta || {},
    alias: index13wDHYALOMMeta?.alias || [],
    redirect: index13wDHYALOMMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/index.vue").then(m => m.default || m)
  },
  {
    name: input_45width_45iconvc6edQEyNLMeta?.name ?? "cabinet-obmenat-bonusu-input-width-icon",
    path: input_45width_45iconvc6edQEyNLMeta?.path ?? "/cabinet/obmenat-bonusu/input-width-icon",
    meta: input_45width_45iconvc6edQEyNLMeta || {},
    alias: input_45width_45iconvc6edQEyNLMeta?.alias || [],
    redirect: input_45width_45iconvc6edQEyNLMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/obmenat-bonusu/input-width-icon.vue").then(m => m.default || m)
  },
  {
    name: indextveZ9oHqaTMeta?.name ?? "cabinet-order-history",
    path: indextveZ9oHqaTMeta?.path ?? "/cabinet/order-history",
    meta: indextveZ9oHqaTMeta || {},
    alias: indextveZ9oHqaTMeta?.alias || [],
    redirect: indextveZ9oHqaTMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: control_45groupKyeTa8AFItMeta?.name ?? "cabinet-uchastiye-v-blagotvoritelnosti-control-group",
    path: control_45groupKyeTa8AFItMeta?.path ?? "/cabinet/uchastiye-v-blagotvoritelnosti/control-group",
    meta: control_45groupKyeTa8AFItMeta || {},
    alias: control_45groupKyeTa8AFItMeta?.alias || [],
    redirect: control_45groupKyeTa8AFItMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/control-group.vue").then(m => m.default || m)
  },
  {
    name: faq_45itemUssVSe2V1eMeta?.name ?? "cabinet-uchastiye-v-blagotvoritelnosti-faq-item",
    path: faq_45itemUssVSe2V1eMeta?.path ?? "/cabinet/uchastiye-v-blagotvoritelnosti/faq-item",
    meta: faq_45itemUssVSe2V1eMeta || {},
    alias: faq_45itemUssVSe2V1eMeta?.alias || [],
    redirect: faq_45itemUssVSe2V1eMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/faq-item.vue").then(m => m.default || m)
  },
  {
    name: index05xlEwWMQIMeta?.name ?? "cabinet-uchastiye-v-blagotvoritelnosti",
    path: index05xlEwWMQIMeta?.path ?? "/cabinet/uchastiye-v-blagotvoritelnosti",
    meta: index05xlEwWMQIMeta || {},
    alias: index05xlEwWMQIMeta?.alias || [],
    redirect: index05xlEwWMQIMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/index.vue").then(m => m.default || m)
  },
  {
    name: input_45width_45icon5MRqpu2A0lMeta?.name ?? "cabinet-uchastiye-v-blagotvoritelnosti-input-width-icon",
    path: input_45width_45icon5MRqpu2A0lMeta?.path ?? "/cabinet/uchastiye-v-blagotvoritelnosti/input-width-icon",
    meta: input_45width_45icon5MRqpu2A0lMeta || {},
    alias: input_45width_45icon5MRqpu2A0lMeta?.alias || [],
    redirect: input_45width_45icon5MRqpu2A0lMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/input-width-icon.vue").then(m => m.default || m)
  },
  {
    name: uchastie_45itemMCz1wQrqXsMeta?.name ?? "cabinet-uchastiye-v-blagotvoritelnosti-uchastie-item",
    path: uchastie_45itemMCz1wQrqXsMeta?.path ?? "/cabinet/uchastiye-v-blagotvoritelnosti/uchastie-item",
    meta: uchastie_45itemMCz1wQrqXsMeta || {},
    alias: uchastie_45itemMCz1wQrqXsMeta?.alias || [],
    redirect: uchastie_45itemMCz1wQrqXsMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uchastiye-v-blagotvoritelnosti/uchastie-item.vue").then(m => m.default || m)
  },
  {
    name: index06oLWBcja2Meta?.name ?? "cabinet-upravlenie-podpiskami",
    path: index06oLWBcja2Meta?.path ?? "/cabinet/upravlenie-podpiskami",
    meta: index06oLWBcja2Meta || {},
    alias: index06oLWBcja2Meta?.alias || [],
    redirect: index06oLWBcja2Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/upravlenie-podpiskami/index.vue").then(m => m.default || m)
  },
  {
    name: aside1GFPSmurJwMeta?.name ?? "cabinet-uslugi-i-services-aside",
    path: aside1GFPSmurJwMeta?.path ?? "/cabinet/uslugi-i-services/aside",
    meta: aside1GFPSmurJwMeta || {},
    alias: aside1GFPSmurJwMeta?.alias || [],
    redirect: aside1GFPSmurJwMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uslugi-i-services/aside.vue").then(m => m.default || m)
  },
  {
    name: indexUQHw8hz7KXMeta?.name ?? "cabinet-uslugi-i-services",
    path: indexUQHw8hz7KXMeta?.path ?? "/cabinet/uslugi-i-services",
    meta: indexUQHw8hz7KXMeta || {},
    alias: indexUQHw8hz7KXMeta?.alias || [],
    redirect: indexUQHw8hz7KXMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uslugi-i-services/index.vue").then(m => m.default || m)
  },
  {
    name: item2HjwSKQCy0Meta?.name ?? "cabinet-uslugi-i-services-item",
    path: item2HjwSKQCy0Meta?.path ?? "/cabinet/uslugi-i-services/item",
    meta: item2HjwSKQCy0Meta || {},
    alias: item2HjwSKQCy0Meta?.alias || [],
    redirect: item2HjwSKQCy0Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/cabinet/uslugi-i-services/item.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QwvThyV9eCMeta?.name ?? "catalog-id",
    path: _91id_93QwvThyV9eCMeta?.path ?? "/catalog/:id()",
    meta: _91id_93QwvThyV9eCMeta || {},
    alias: _91id_93QwvThyV9eCMeta?.alias || [],
    redirect: _91id_93QwvThyV9eCMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/catalog/[id].vue").then(m => m.default || m)
  },
  {
    name: _91productsCode_93UBJ442fXHiMeta?.name ?? "catalog-sectionCode-productsCode",
    path: _91productsCode_93UBJ442fXHiMeta?.path ?? "/catalog/:sectionCode()/:productsCode()",
    meta: _91productsCode_93UBJ442fXHiMeta || {},
    alias: _91productsCode_93UBJ442fXHiMeta?.alias || [],
    redirect: _91productsCode_93UBJ442fXHiMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/catalog/[sectionCode]/[productsCode].vue").then(m => m.default || m)
  },
  {
    name: indexxsYa8zQbB3Meta?.name ?? "catalog",
    path: indexxsYa8zQbB3Meta?.path ?? "/catalog",
    meta: indexxsYa8zQbB3Meta || {},
    alias: indexxsYa8zQbB3Meta?.alias || [],
    redirect: indexxsYa8zQbB3Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexuOiG9RhEPMMeta?.name ?? "compare",
    path: indexuOiG9RhEPMMeta?.path ?? "/compare",
    meta: indexuOiG9RhEPMMeta || {},
    alias: indexuOiG9RhEPMMeta?.alias || [],
    redirect: indexuOiG9RhEPMMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/compare/index.vue").then(m => m.default || m)
  },
  {
    name: contactsoV4QxweRrjMeta?.name ?? "contacts",
    path: contactsoV4QxweRrjMeta?.path ?? "/contacts",
    meta: contactsoV4QxweRrjMeta || {},
    alias: contactsoV4QxweRrjMeta?.alias || [],
    redirect: contactsoV4QxweRrjMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexvFhMdWlon2Meta?.name ?? "delayed-products",
    path: indexvFhMdWlon2Meta?.path ?? "/delayed-products",
    meta: indexvFhMdWlon2Meta || {},
    alias: indexvFhMdWlon2Meta?.alias || [],
    redirect: indexvFhMdWlon2Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/delayed-products/index.vue").then(m => m.default || m)
  },
  {
    name: index3VaZSIUNVUMeta?.name ?? "index",
    path: index3VaZSIUNVUMeta?.path ?? "/",
    meta: index3VaZSIUNVUMeta || {},
    alias: index3VaZSIUNVUMeta?.alias || [],
    redirect: index3VaZSIUNVUMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjeJurck7SXMeta?.name ?? "info",
    path: indexjeJurck7SXMeta?.path ?? "/info",
    meta: indexjeJurck7SXMeta || {},
    alias: indexjeJurck7SXMeta?.alias || [],
    redirect: indexjeJurck7SXMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsCode_93WLC9CFEermMeta?.name ?? "info-news-newsCode",
    path: _91newsCode_93WLC9CFEermMeta?.path ?? "/info/news/:newsCode()",
    meta: _91newsCode_93WLC9CFEermMeta || {},
    alias: _91newsCode_93WLC9CFEermMeta?.alias || [],
    redirect: _91newsCode_93WLC9CFEermMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/news/[newsCode].vue").then(m => m.default || m)
  },
  {
    name: indexMMuSGv5hBOMeta?.name ?? "info-news",
    path: indexMMuSGv5hBOMeta?.path ?? "/info/news",
    meta: indexMMuSGv5hBOMeta || {},
    alias: indexMMuSGv5hBOMeta?.alias || [],
    redirect: indexMMuSGv5hBOMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/news/index.vue").then(m => m.default || m)
  },
  {
    name: search9F9YORz8QYMeta?.name ?? "info-news-search",
    path: search9F9YORz8QYMeta?.path ?? "/info/news/search",
    meta: search9F9YORz8QYMeta || {},
    alias: search9F9YORz8QYMeta?.alias || [],
    redirect: search9F9YORz8QYMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/news/search.vue").then(m => m.default || m)
  },
  {
    name: _91articleCode_93nbcTYD8rLZMeta?.name ?? "info-obzory-articleCode",
    path: _91articleCode_93nbcTYD8rLZMeta?.path ?? "/info/obzory/:articleCode()",
    meta: _91articleCode_93nbcTYD8rLZMeta || {},
    alias: _91articleCode_93nbcTYD8rLZMeta?.alias || [],
    redirect: _91articleCode_93nbcTYD8rLZMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/obzory/[articleCode].vue").then(m => m.default || m)
  },
  {
    name: indexygilMXGFVnMeta?.name ?? "info-obzory",
    path: indexygilMXGFVnMeta?.path ?? "/info/obzory",
    meta: indexygilMXGFVnMeta || {},
    alias: indexygilMXGFVnMeta?.alias || [],
    redirect: indexygilMXGFVnMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/obzory/index.vue").then(m => m.default || m)
  },
  {
    name: searchIFsC6D4BeOMeta?.name ?? "info-obzory-search",
    path: searchIFsC6D4BeOMeta?.path ?? "/info/obzory/search",
    meta: searchIFsC6D4BeOMeta || {},
    alias: searchIFsC6D4BeOMeta?.alias || [],
    redirect: searchIFsC6D4BeOMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/obzory/search.vue").then(m => m.default || m)
  },
  {
    name: _91articleCode_93zX8jNMViTWMeta?.name ?? "info-stati-articleCode",
    path: _91articleCode_93zX8jNMViTWMeta?.path ?? "/info/stati/:articleCode()",
    meta: _91articleCode_93zX8jNMViTWMeta || {},
    alias: _91articleCode_93zX8jNMViTWMeta?.alias || [],
    redirect: _91articleCode_93zX8jNMViTWMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/stati/[articleCode].vue").then(m => m.default || m)
  },
  {
    name: indexjIgovweT8zMeta?.name ?? "info-stati",
    path: indexjIgovweT8zMeta?.path ?? "/info/stati",
    meta: indexjIgovweT8zMeta || {},
    alias: indexjIgovweT8zMeta?.alias || [],
    redirect: indexjIgovweT8zMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/stati/index.vue").then(m => m.default || m)
  },
  {
    name: searchYpG7sGoaAjMeta?.name ?? "info-stati-search",
    path: searchYpG7sGoaAjMeta?.path ?? "/info/stati/search",
    meta: searchYpG7sGoaAjMeta || {},
    alias: searchYpG7sGoaAjMeta?.alias || [],
    redirect: searchYpG7sGoaAjMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/stati/search.vue").then(m => m.default || m)
  },
  {
    name: _91articleCode_93IUlMaUZm9ZMeta?.name ?? "info-video-articleCode",
    path: _91articleCode_93IUlMaUZm9ZMeta?.path ?? "/info/video/:articleCode()",
    meta: _91articleCode_93IUlMaUZm9ZMeta || {},
    alias: _91articleCode_93IUlMaUZm9ZMeta?.alias || [],
    redirect: _91articleCode_93IUlMaUZm9ZMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/video/[articleCode].vue").then(m => m.default || m)
  },
  {
    name: indexQ7FdiA3oKsMeta?.name ?? "info-video",
    path: indexQ7FdiA3oKsMeta?.path ?? "/info/video",
    meta: indexQ7FdiA3oKsMeta || {},
    alias: indexQ7FdiA3oKsMeta?.alias || [],
    redirect: indexQ7FdiA3oKsMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/video/index.vue").then(m => m.default || m)
  },
  {
    name: searchWqhb5hgMATMeta?.name ?? "info-video-search",
    path: searchWqhb5hgMATMeta?.path ?? "/info/video/search",
    meta: searchWqhb5hgMATMeta || {},
    alias: searchWqhb5hgMATMeta?.alias || [],
    redirect: searchWqhb5hgMATMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/info/video/search.vue").then(m => m.default || m)
  },
  {
    name: _91page_93Qjzxb1ik9cMeta?.name ?? "kompaniya-page",
    path: _91page_93Qjzxb1ik9cMeta?.path ?? "/kompaniya/:page()",
    meta: _91page_93Qjzxb1ik9cMeta || {},
    alias: _91page_93Qjzxb1ik9cMeta?.alias || [],
    redirect: _91page_93Qjzxb1ik9cMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/kompaniya/[page].vue").then(m => m.default || m)
  },
  {
    name: indexSioaamhxvCMeta?.name ?? "print-basket",
    path: indexSioaamhxvCMeta?.path ?? "/print/basket",
    meta: indexSioaamhxvCMeta || {},
    alias: indexSioaamhxvCMeta?.alias || [],
    redirect: indexSioaamhxvCMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/basket/index.vue").then(m => m.default || m)
  },
  {
    name: indexoYQgrc62lMMeta?.name ?? "print-card",
    path: indexoYQgrc62lMMeta?.path ?? "/print/card",
    meta: indexoYQgrc62lMMeta || {},
    alias: indexoYQgrc62lMMeta?.alias || [],
    redirect: indexoYQgrc62lMMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/card/index.vue").then(m => m.default || m)
  },
  {
    name: index4hGNnEhh87Meta?.name ?? "print-delayed-products",
    path: index4hGNnEhh87Meta?.path ?? "/print/delayed-products",
    meta: index4hGNnEhh87Meta || {},
    alias: index4hGNnEhh87Meta?.alias || [],
    redirect: index4hGNnEhh87Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/delayed-products/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93grPyzByG2YMeta?.name ?? "print-order-orderId",
    path: _91orderId_93grPyzByG2YMeta?.path ?? "/print/order/:orderId()",
    meta: _91orderId_93grPyzByG2YMeta || {},
    alias: _91orderId_93grPyzByG2YMeta?.alias || [],
    redirect: _91orderId_93grPyzByG2YMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/order/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexiovkuaVA2wMeta?.name ?? "print-order",
    path: indexiovkuaVA2wMeta?.path ?? "/print/order",
    meta: indexiovkuaVA2wMeta || {},
    alias: indexiovkuaVA2wMeta?.alias || [],
    redirect: indexiovkuaVA2wMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/print/order/index.vue").then(m => m.default || m)
  },
  {
    name: _91productCode_93YyA9YHd2ojMeta?.name ?? "product-categoryCode-productCode",
    path: _91productCode_93YyA9YHd2ojMeta?.path ?? "/product/:categoryCode()/:productCode()",
    meta: _91productCode_93YyA9YHd2ojMeta || {},
    alias: _91productCode_93YyA9YHd2ojMeta?.alias || [],
    redirect: _91productCode_93YyA9YHd2ojMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/product/[categoryCode]/[productCode].vue").then(m => m.default || m)
  },
  {
    name: searcheGOU2xvWbQMeta?.name ?? "product-search",
    path: searcheGOU2xvWbQMeta?.path ?? "/product/search",
    meta: searcheGOU2xvWbQMeta || {},
    alias: searcheGOU2xvWbQMeta?.alias || [],
    redirect: searcheGOU2xvWbQMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/product/search.vue").then(m => m.default || m)
  },
  {
    name: _91vendorCode_93mzZjpyuUhSMeta?.name ?? "proizvoditeli-vendorCode",
    path: _91vendorCode_93mzZjpyuUhSMeta?.path ?? "/proizvoditeli/:vendorCode()",
    meta: _91vendorCode_93mzZjpyuUhSMeta || {},
    alias: _91vendorCode_93mzZjpyuUhSMeta?.alias || [],
    redirect: _91vendorCode_93mzZjpyuUhSMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/proizvoditeli/[vendorCode].vue").then(m => m.default || m)
  },
  {
    name: indexWAfaiCZnQXMeta?.name ?? "proizvoditeli",
    path: indexWAfaiCZnQXMeta?.path ?? "/proizvoditeli",
    meta: indexWAfaiCZnQXMeta || {},
    alias: indexWAfaiCZnQXMeta?.alias || [],
    redirect: indexWAfaiCZnQXMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/proizvoditeli/index.vue").then(m => m.default || m)
  },
  {
    name: index2xPdtwvBQ3Meta?.name ?? "reg",
    path: index2xPdtwvBQ3Meta?.path ?? "/reg",
    meta: index2xPdtwvBQ3Meta || {},
    alias: index2xPdtwvBQ3Meta?.alias || [],
    redirect: index2xPdtwvBQ3Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/reg/index.vue").then(m => m.default || m)
  },
  {
    name: indexzK4nDk6AlcMeta?.name ?? "reg-success",
    path: indexzK4nDk6AlcMeta?.path ?? "/reg/success",
    meta: indexzK4nDk6AlcMeta || {},
    alias: indexzK4nDk6AlcMeta?.alias || [],
    redirect: indexzK4nDk6AlcMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/reg/success/index.vue").then(m => m.default || m)
  },
  {
    name: searchA2GYzyEIIqMeta?.name ?? "search",
    path: searchA2GYzyEIIqMeta?.path ?? "/search",
    meta: searchA2GYzyEIIqMeta || {},
    alias: searchA2GYzyEIIqMeta?.alias || [],
    redirect: searchA2GYzyEIIqMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/search.vue").then(m => m.default || m)
  },
  {
    name: vacancies5m50Vd8JeGMeta?.name ?? "vacancies",
    path: vacancies5m50Vd8JeGMeta?.path ?? "/vacancies",
    meta: vacancies5m50Vd8JeGMeta || {},
    alias: vacancies5m50Vd8JeGMeta?.alias || [],
    redirect: vacancies5m50Vd8JeGMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/vacancies.vue").then(m => m.default || m)
  },
  {
    name: _91vendorCode_93z2mheCOWZXMeta?.name ?? "vendors-vendorCode",
    path: _91vendorCode_93z2mheCOWZXMeta?.path ?? "/vendors/:vendorCode()",
    meta: _91vendorCode_93z2mheCOWZXMeta || {},
    alias: _91vendorCode_93z2mheCOWZXMeta?.alias || [],
    redirect: _91vendorCode_93z2mheCOWZXMeta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/vendors/[vendorCode].vue").then(m => m.default || m)
  },
  {
    name: index4SLDJuOjJ9Meta?.name ?? "vendors",
    path: index4SLDJuOjJ9Meta?.path ?? "/vendors",
    meta: index4SLDJuOjJ9Meta || {},
    alias: index4SLDJuOjJ9Meta?.alias || [],
    redirect: index4SLDJuOjJ9Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/vendors/index.vue").then(m => m.default || m)
  },
  {
    name: yandbtmKzWnnT2w30Meta?.name ?? "yandbtm",
    path: yandbtmKzWnnT2w30Meta?.path ?? "/yandbtm",
    meta: yandbtmKzWnnT2w30Meta || {},
    alias: yandbtmKzWnnT2w30Meta?.alias || [],
    redirect: yandbtmKzWnnT2w30Meta?.redirect || undefined,
    component: () => import("/home/bitrix/ext_www/t-mash.ru/tmash-front/pages/yandbtm.vue").then(m => m.default || m)
  }
]