import {defineStore} from 'pinia';
import {ref, useRouter} from '#imports';
import {useAxiosInstance} from '@/composables/useAxiosInstance';
import {fetchFromApi, useAuthFetch} from '@/composables/useRequest';
import {
  CHECK_LOGIN,
  FORGOT_PASSWORD,
  LOGIN,
  LOGOUT,
  REGISTER,
  REGISTER_FAST,
  RESET_PASSWORD,
} from '@/composables/useURL';
import {useBasketStore} from '@/stores/basket';
import {useCabinetGoodsStore} from '@/stores/cabinetGoods';
import {useCompareStore} from '@/stores/compare';
import type {IAuthForm, IResetPassFormReq} from '@/types/pages/auth';
import type {IRegForm} from '@/types/pages/cabinet/company';
import type {IAuthResponse, ICheckAuthResponse} from '@/types/store/auth';

interface IRegistration {
  status: boolean;
  message: string;
  code?: number;
}

const smallGet = async () => {
  const basket = useBasketStore();
  const compare = useCompareStore();
  const favorite = useCabinetGoodsStore();
  await basket.get();
  await compare.get();
  await favorite.getProductsInFavorite();
};

const forgotPass = async (login: string) => {
  const data = await fetchFromApi(FORGOT_PASSWORD, {
    method: 'POST',
    credentials: 'include',
    body: {login},
  });
  return data;
};

const resetPass = async (form: IResetPassFormReq) => {
  const data = (await fetchFromApi(RESET_PASSWORD, {
    method: 'POST',
    credentials: 'include',
    body: form,
  })) as IAuthResponse;
  return data;
};

// ??
const fastRegistration = async (body: FormDataEntryValue) => {
  const axios = useAxiosInstance();
  return await axios.post(REGISTER_FAST, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const useAuthStore = defineStore('auth', () => {
  const name = ref('');
  const isLoaded = ref(false);
  const isAuth = ref(false);
  const isMain = ref(false);
  const canWatchPriceDetails = ref(false);
  const isRegistrationSuccess = ref<IRegistration>({status: false, message: ''});

  const checkAuth = async () => {
    const data = (await fetchFromApi(CHECK_LOGIN, {
      credentials: 'include',
    })) as ICheckAuthResponse;
    isAuth.value = data.isAuth ?? false;
    isMain.value = data.isMain ?? false;
    canWatchPriceDetails.value = data.canWatchPriceDetails ?? false;
    isLoaded.value = true;
  };

  // eslint-disable-next-line consistent-return
  const getLogin = async (authData: IAuthForm) => {
    const data = (await fetchFromApi(LOGIN, {
      method: 'POST',
      credentials: 'include',
      body: authData,
    })) as IAuthResponse;
    if (data.code) {
      return data.message;
    }
    await smallGet();
    checkAuth();
  };

  const logout = async () => {
    const router = useRouter();
    await useAuthFetch(LOGOUT);
    checkAuth();
    await smallGet();
    void router.push(LOGIN);
  };

  const fullRegistration = async (body: IRegForm) => {
    const data = (await fetchFromApi(REGISTER, {
      method: 'POST',
      credentials: 'include',
      body,
    })) as IAuthResponse;
    if (data.status) {
      isRegistrationSuccess.value.status = data.status;
      isRegistrationSuccess.value.message = data.message ?? '';
    } else {
      isRegistrationSuccess.value.status = false;
      isRegistrationSuccess.value.message = data.message ?? '';
    }
  };

  const clearRegStatus = () => {
    isRegistrationSuccess.value = {
      status: false,
      message: '',
    };
  };

  return {
    name,
    isLoaded,
    isAuth,
    isMain,
    canWatchPriceDetails,
    isRegistrationSuccess,

    getLogin,
    checkAuth,
    logout,
    forgotPass,
    resetPass,
    fastRegistration,
    fullRegistration,
    clearRegStatus,
  };
});
